import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { useBanksFromServer, useCurrenciesFromServer, useMerchantsFromServer } from 'hooks/useDataFromServer';
import { requisiteTypesToTitlesMap } from 'types/requisiteType.enum';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'Номер заявки мерчанта',
    name: 'remoteOrderId',
    type: 'input',
  },
  {
    title: 'Сумма ОТ',
    name: 'sumMin',
    type: 'input-number',
    isAmountInput: true,
  },
  {
    title: 'Сумма ДО',
    name: 'sumMax',
    type: 'input-number',
    isAmountInput: true,
  },
  {
    title: 'Период',
    name: 'range',
    type: 'date-picker-showtime',
    allowClear: true,
    rangeFields: ['from', 'to'],
  },
  {
    title: 'Мерчант',
    name: 'merchantId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useMerchantsFromServer,
    cacheKey: 'merchantId-select',
  },
  {
    title: 'Банк',
    name: 'bankId',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useBanksFromServer,
    cacheKey: 'bankId-filter',
  },
  {
    title: 'Валюта',
    name: 'currencyCode',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useCurrenciesFromServer,
    cacheKey: 'currency-select',
    dataTransformer: (item) => {
      return {
        label: item.name,
        value: item.code,
      };
    },
  },
  {
    title: 'Тип реквизита',
    name: 'requisitesType',
    type: 'select',
    allowClear: true,
    options: Object.entries(requisiteTypesToTitlesMap).map(([value, label]) => ({ label, value })),
  },
];
