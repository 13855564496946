import instance from 'utils/axios';
import { BankConfirmWithEnum } from 'modules/banks/enums';

export interface SetConfirmWithRequestParams {
  id: number;
  confirmWith: BankConfirmWithEnum;
}

export async function setConfirmWithRequest(params: SetConfirmWithRequestParams) {
  await instance.post('/banks/set-confirm-with', { ...params });
}
