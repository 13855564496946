import { Image, Form, Col, Input } from 'antd';
import React, { useMemo } from 'react';

import { ColumnType } from 'antd/lib/table';

import { useCurrenciesFromServer } from 'hooks/useDataFromServer';

import { Tooltip } from 'components/Tooltip';
import { TableMerchantCommission } from '../types';
import { EditMerchantCommissionsDrawerInputColumn } from '../components/InputColumn';

export const useEditMerchantCommissionsDrawerColumns = (): ColumnType<TableMerchantCommission>[] => {
  const [currencies] = useCurrenciesFromServer();

  return useMemo<ColumnType<TableMerchantCommission>[]>(
    () => [
      {
        title: 'Провайдер',
        key: 'providerId',
        render: (_, { provider }, index) => {
          return (
            <>
              <Tooltip title={provider.name}>
                <Image src={provider.image} alt={provider.name} preview={false} width={60} />
              </Tooltip>
              <Form.Item hidden name={[index, 'provider']} initialValue={provider}>
                <Input type="hidden" />
              </Form.Item>
            </>
          );
        },
      },
      {
        title: 'Код валюты',
        key: 'currencyCode',
        dataIndex: 'currencyCode',
        render: (value, _, index) => {
          const currency = currencies.find((currency) => currency.code === value);

          return (
            <>
              <Col>
                <div>{value}</div>
                {currency && <div className="text-gray-400">{currency?.name}</div>}
              </Col>
              <Form.Item name={[index, 'currencyCode']} initialValue={value} hidden>
                <Input type="hidden" />
              </Form.Item>
            </>
          );
        },
      },
      {
        title: 'Входящие',
        key: 'incomingValue',
        render: (_, commission, index) => {
          return (
            <EditMerchantCommissionsDrawerInputColumn
              commission={commission}
              index={index}
              p2pColumnName="p2pIncomingValue"
              c2cColumnName="c2cIncomingValue"
            />
          );
        },
      },
      {
        title: 'Исходящие',
        key: 'outcomingValue',
        render: (_, commission, index) => {
          return (
            <EditMerchantCommissionsDrawerInputColumn
              commission={commission}
              index={index}
              p2pColumnName="p2pOutcomingValue"
              c2cColumnName="c2cOutcomingValue"
            />
          );
        },
      },
    ],
    [currencies],
  );
};
