import instance from 'utils/axios';

export async function fetchMerchantBalancesByCurrenciesRequest(
  merchantId: number,
): Promise<{ currencyCode: string; amount: number }[]> {
  const {
    data: { data },
  } = await instance.get('/merchants/balances-by-currencies', { params: { merchantId } });

  return data;
}
