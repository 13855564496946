import instance from 'utils/axios';
import {
  GoipsActionTypes,
  IGetGoipsReducerAction,
  ISetIsLoadingGoipsReducerAction,
} from 'modules/goips/store/goips/types';
import { AppDispatch } from 'app/store';

export function getGoips() {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { data },
      } = await instance.get('/goip');

      dispatch<IGetGoipsReducerAction>({
        type: GoipsActionTypes.GET_GOIPS,
        payload: { goips: data },
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function setIsLoading(payload: boolean) {
  return (dispatch: AppDispatch) => {
    dispatch<ISetIsLoadingGoipsReducerAction>({
      type: GoipsActionTypes.SET_IS_LOADING,
      payload,
    });
  };
}
