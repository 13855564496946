import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTheme } from 'contexts';
import { LazyTableHeaderColumn } from 'components/LazyTable/components/ListView/components/Header/components/Column/LazyTableHeaderColumn';

interface Props<RecordType = any> {
  columns: ColumnsType<RecordType>;
  onColumnResize: (index: string, width: number) => void;
  isExpandable: boolean;
}

export const LazyTableHeader: React.FC<Props> = ({ columns, onColumnResize, isExpandable }) => {
  const { currentTheme } = useTheme();

  const backgroundColor = currentTheme.token?.colorBgTableHeader;

  return (
    <div
      className="p-4 flex flex-row items-center text-center font-semibold shadow-sm rounded-t-md"
      style={{ backgroundColor, paddingLeft: isExpandable ? 30 : 0 }}
    >
      {columns.map((column, index) => (
        <LazyTableHeaderColumn
          title={column.title as string}
          key={index}
          columnKey={String(index)}
          onResize={onColumnResize}
          width={column.width}
        />
      ))}
    </div>
  );
};
