import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';
import instance from 'utils/axios';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';
import { PartnersState } from './types/partnersState.interface';
import { FetchPartnersParams } from './types/fetchPartnersParams.interface';
import { UpdatePartnerParams } from './types/updatePartnerParams.interface';

const createSlice = buildCreateSlice({ creators: { asyncThunk: asyncThunkCreator } });

const partnersSlice = createSlice({
  name: 'partners',
  initialState: {
    partners: [],
    isLoading: false,
    pagination: {
      page: 1,
      pages: 0,
      perPage: 20,
      total: 0,
    },
  } as PartnersState,
  reducers: (create) => ({
    fetchPartners: create.asyncThunk(
      async (params: FetchPartnersParams & RequestParamsWithPagination) => {
        const { data } = await instance.get('partners', { params: sanitizeRequestParams(params) });

        return data;
      },
      {
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.partners = action.payload.data;
          state.pagination = action.payload.meta;
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      },
    ),
    updatePartner: create.asyncThunk(
      async (params: UpdatePartnerParams) => {
        const {
          data: { data },
        } = await instance.post('partners/update', params);

        return data;
      },
      {
        pending: (state) => {
          state.isLoading = true;
        },
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.partners = state.partners.map((partner) =>
            partner.id === action.payload.id ? action.payload : partner,
          );
        },
        rejected: (state) => {
          state.isLoading = false;
        },
      },
    ),
    enablePartner: create.asyncThunk(
      async (id: number) => {
        await instance.post('partners/enable', { id });

        return id;
      },
      {
        pending: (state) => {
          state.isLoading = true;
        },
        fulfilled: (state, { payload }) => {
          state.isLoading = false;
          state.partners = state.partners.map((partner) =>
            partner.id === payload
              ? {
                  ...partner,
                  enabled: true,
                }
              : partner,
          );
        },
        rejected: (state) => {
          state.isLoading = false;
        },
      },
    ),
    disablePartner: create.asyncThunk(
      async (id: number) => {
        await instance.post('partners/disable', { id });

        return id;
      },
      {
        pending: (state) => {
          state.isLoading = true;
        },
        fulfilled: (state, { payload }) => {
          state.isLoading = false;
          state.partners = state.partners.map((partner) =>
            partner.id === payload
              ? {
                  ...partner,
                  enabled: false,
                }
              : partner,
          );
        },
        rejected: (state) => {
          state.isLoading = false;
        },
      },
    ),
  }),
});

export const partnersReducer = partnersSlice.reducer;

export const { fetchPartners, updatePartner, enablePartner, disablePartner } = partnersSlice.actions;
