import instance from 'utils/axios';
import { AppDispatch } from 'app/store';
import {
  IFetchConfigurationReducerAction,
  ConfigurationActionTypes,
  ISetIsLoadingConfigurationReducerAction,
  ConfigurationValueValueType,
} from './types';

export function fetchConfiguration() {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { data },
      } = await instance.get('/config');

      dispatch<IFetchConfigurationReducerAction>({
        type: ConfigurationActionTypes.FETCH_CONFIGURATION,
        payload: data,
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function updateConfiguration(updatedValues: { key: string; value: ConfigurationValueValueType }[]) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoading(true));

      await instance.post('/config/update', updatedValues);

      await dispatch(fetchConfiguration());
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function setIsLoading(payload: boolean) {
  return (dispatch: AppDispatch) => {
    dispatch<ISetIsLoadingConfigurationReducerAction>({
      type: ConfigurationActionTypes.SET_IS_LODAING,
      payload,
    });
  };
}
