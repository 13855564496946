import React from 'react';
import { Statistic } from 'antd';

import { IOwner } from 'modules/owners/types/IOwner';
import { Status } from 'components/Status/Status';
import { StatusesEnum } from 'components/Status/StatusesEnum';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { LazyTableColumnsType } from 'components/LazyTable/types/lazyTableColumns.type';
import { useAgentsFromServer } from 'hooks/useDataFromServer';
import { OwnersPageTableStatusColumn } from 'modules/owners/pages/list/components/StatusColumn';
import { OwnersPageDataViewAcceptableBalanceColumn } from 'modules/owners/pages/list/components/AcceptableBalanceColumn';
import { OwnersPageTableTransactionsIsAllowedColumn } from 'modules/owners/pages/list/components/TransactionsIsAllowedColumn';
import { OwnersPageTableRowActions } from 'modules/owners/pages/list/components/RowActions';

interface IMakeColumnsConfig {
  onEditPress: (owner: IOwner) => void;
  onCardPress: (owner: IOwner) => void;
  onDeletePress: (ownerId: number) => void;
  onAssignToCustomerPress: (owner: IOwner) => void;
}

export function useColumns({
  onAssignToCustomerPress,
  onEditPress,
  onDeletePress,
  onCardPress,
}: IMakeColumnsConfig): LazyTableColumnsType<IOwner> {
  const [agents] = useAgentsFromServer();

  return [
    {
      title: 'ID',
      key: 'id',
      width: 51,
      dataIndex: 'ownerId',
    },
    {
      title: 'Статус',
      key: 'status',
      width: 120,
      render: (_, owner: IOwner) => (
        <ProtectedComponent requiredPermissions={[permissions.owner.updateField.status]}>
          <OwnersPageTableStatusColumn owner={owner} />
        </ProtectedComponent>
      ),
    },
    {
      title: 'Расчетный баланс',
      width: '9%',
      key: 'balancePlanned',
      render: (_, { balancePlanned }: IOwner) => (
        <>
          {balancePlanned ? (
            <Statistic
              valueStyle={{
                fontSize: '14px',
                fontWeight: 'unset',
              }}
              value={balancePlanned?.toFixed(0)}
              groupSeparator={' '}
            />
          ) : (
            'Нет данных'
          )}
        </>
      ),
    },
    {
      title: 'Фактический баланс',
      key: 'balancePlanned',
      width: '9%',
      render: (_, { balanceActual }: IOwner) => (
        <>
          {balanceActual ? (
            <Statistic
              valueStyle={{
                fontSize: '14px',
                fontWeight: 'unset',
              }}
              value={balanceActual?.toFixed(0)}
              groupSeparator={' '}
            />
          ) : (
            'Нет данных'
          )}
        </>
      ),
    },
    {
      title: 'Можно принять',
      key: 'acceptableBalance',
      render: (_, { balanceActual, trustLimit }: IOwner) => (
        <OwnersPageDataViewAcceptableBalanceColumn balanceActual={balanceActual} trustLimit={trustLimit} />
      ),
    },
    {
      title: 'Вх. транз. разрешены',
      key: 'incommingTransactionsIsAllowed',
      width: 80,
      render: (_, owner: IOwner) => (
        <ProtectedComponent requiredPermissions={[permissions.owner.updateField.incomeStatus]}>
          <OwnersPageTableTransactionsIsAllowedColumn owner={owner} />
        </ProtectedComponent>
      ),
    },
    {
      title: 'Исх. транз. разрешены',
      key: 'outcommingTransactionsIsAllowed',
      width: 80,
      render: (_, owner: IOwner) => (
        <ProtectedComponent requiredPermissions={[permissions.owner.updateField.outcomeStatus]}>
          <OwnersPageTableTransactionsIsAllowedColumn forOutcomming owner={owner} />
        </ProtectedComponent>
      ),
    },
    {
      title: 'Пинг',
      key: 'pingStatus',
      dataIndex: 'pingStatus',
      align: 'center',
      render: (_, { pingStatus }: IOwner) => {
        const status = pingStatus ? StatusesEnum.SUCCESS : StatusesEnum.ERROR;

        return <Status status={status} />;
      },
    },
    {
      title: 'ФИО',
      key: 'fio',
      dataIndex: 'fio',
      width: '9%',
      breakWord: false,
    },
    {
      title: 'Агент',
      key: 'agent',
      dataIndex: 'agent.id',
      width: '3%',
      render: (_, owner: IOwner) => {
        if (!owner.agentId) return null;

        const agentName = agents.find((agent) => agent.id === owner.agentId)?.name;

        if (!agentName) {
          return <div>Не найден</div>;
        }

        return (
          <a className="text-primaryColor" href={`/agents?name=${agentName}`}>
            {agentName}
          </a>
        );
      },
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      width: 200,
      render: (_, owner: IOwner) => (
        <OwnersPageTableRowActions
          onEditPress={() => onEditPress(owner)}
          onDeletePress={() => onDeletePress(owner.ownerId)}
          onAssignToCustomerPress={() => onAssignToCustomerPress(owner)}
          onCardPress={() => onCardPress(owner)}
        />
      ),
    },
  ];
}
