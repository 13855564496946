import React from 'react';

import { updateOwnerField } from 'modules/owners/store/owners/actions';

import { IOwner } from 'modules/owners/types/IOwner';
import { useOwnerStatusesFromServer } from 'hooks/useDataFromServer';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { LazySelect } from 'components/LazySelect';

interface Props {
  owner: IOwner;
}

export const OwnersPageTableStatusColumn: React.FC<Props> = ({ owner }) => {
  const dispatch = useAppDispatch();

  function handleStatusChange(value: number) {
    dispatch(updateOwnerField(owner.ownerId, 'status', value));
  }

  return (
    <LazySelect
      placeholder="Cтатус"
      value={owner.status.id}
      className="w-full"
      onChange={handleStatusChange}
      useDataHook={useOwnerStatusesFromServer}
      cacheKey="owner-status-select"
      fetchIfNoCache
    />
  );
};
