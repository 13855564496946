import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';
import instance from 'utils/axios';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';
import { RejectedTransactionsState } from './types/rejectedTransactionsState.interface';
import { FetchRejectedTransactionsParams } from './types/fetchRejectedTransactionsParams.interface';

const createSlice = buildCreateSlice({ creators: { asyncThunk: asyncThunkCreator } });

const rejectedTransactionsSlice = createSlice({
  name: 'rejectedTransactions',
  initialState: {
    rejectedTransactions: [],
    isLoading: false,
    pagination: {
      page: 1,
      pages: 0,
      perPage: 20,
      total: 0,
    },
  } as RejectedTransactionsState,
  reducers: (create) => ({
    fetchRejectedTransactions: create.asyncThunk(
      async (params: FetchRejectedTransactionsParams & RequestParamsWithPagination) => {
        const { data } = await instance.get('rejected-orders/list', { params: sanitizeRequestParams(params) });

        return data;
      },
      {
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.rejectedTransactions = action.payload.data;
          state.pagination = action.payload.meta;
        },
        rejected: (state) => {
          state.isLoading = false;
        },
        pending: (state) => {
          state.isLoading = true;
        },
      },
    ),
  }),
});

export const rejectedTransactionsReducer = rejectedTransactionsSlice.reducer;

export const { fetchRejectedTransactions } = rejectedTransactionsSlice.actions;
