export function sanitizeRequestParams<T extends { [key: string]: any }>(
  params: T,
  excludeCheckValues: unknown[] = [0, false],
): Partial<T> {
  return Object.fromEntries(
    Object.entries(params)
      .filter(([_, value]) => {
        if (excludeCheckValues.includes(value)) {
          return true;
        }

        return !!value;
      })
      .map(([key, value]) => {
        if (value === true) {
          value = 1;
        } else if (value === false) {
          value = 0;
        }

        return [key, value];
      }),
  ) as Partial<T>;
}
