import React, { useEffect, useState } from 'react';

import { Button, App, Drawer } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { UsersPageTable } from 'modules/users/pages/list/components/Table';
import { UpdateUserForm } from 'modules/users/components/UpdateUserForm/UpdateUserForm';
import { CreateUserForm } from 'modules/users/components/CreateUserForm/CreateUserForm';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';

import { filtersSchema } from 'modules/users/pages/list/filters';

import { IFetchUsersParams } from 'modules/users/store/users/types';
import { fetchUsers, setPagination } from 'modules/users/store/users/actions';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { Pagination } from 'components/Pagination';
import { Filters } from 'components/Filters';

export const UsersPage: React.FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<IFetchUsersParams>();

  const [updateUserId, setUpdateUserId] = useState<number>();
  const [isCreateDrawerShown, setIsCreateDrawerShown] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  const {
    pagination,
    pagination: { perPage, page },
    isLoading,
  } = useAppSelector((state) => state.users);

  useEffect(() => {
    fetch(page);
  }, [memoFilters, page, perPage]);

  useEffect(() => {
    fetch(1);
  }, [memoFilters]);

  function handlePaginationChange(page: number, perPage: number) {
    dispatch(setPagination(page, perPage));
  }

  async function fetch(requestedPage = page) {
    try {
      await dispatch(fetchUsers({ ...memoFilters, page: requestedPage, perPage }));
    } catch {
      message.error('Не удалось получить список пользователей');
    }
  }

  return (
    <>
      <Drawer
        open={!!updateUserId}
        onClose={() => setUpdateUserId(undefined)}
        title={`Редактирования пользователя №${updateUserId}`}
      >
        {updateUserId && (
          <UpdateUserForm
            userId={updateUserId}
            onSubmit={() => {
              setUpdateUserId(undefined);

              fetch();
            }}
          />
        )}
      </Drawer>
      <Drawer open={isCreateDrawerShown} title="Создать пользователя" onClose={() => setIsCreateDrawerShown(false)}>
        <CreateUserForm
          onSubmit={() => {
            setIsCreateDrawerShown(false);

            fetch();
          }}
        />
      </Drawer>
      <Filters
        headerTitle="Пользователи"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        extraHeaderButtons={() => (
          <ProtectedComponent requiredPermissions={[permissions.user.create]} fallbackComponent={<div />}>
            <Button icon={<PlusOutlined />} shape="round" type="primary" onClick={() => setIsCreateDrawerShown(true)}>
              Создать
            </Button>
          </ProtectedComponent>
        )}
      />
      <UsersPageTable onUserEditPress={({ id }) => setUpdateUserId(id)} />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
