import React, { useEffect, useState } from 'react';

import { App, Button, Table } from 'antd';

import { PlusCircleOutlined } from '@ant-design/icons';

import { Filters } from 'components/Filters';

import { Pagination } from 'components/Pagination';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';

import { changePagination, deleteMerchant, fetchMerchants } from 'modules/merchants/store/merchants/actions';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';

import {
  CreateMerchantDrawer,
  EditMerchantCommissionsDrawer,
  EditMerchantDrawer,
  MerchantBalancesDrawer,
  MerchantTokensDrawer,
} from 'modules/merchants/components';

import { Merchant } from 'modules/merchants/types';
import { FetchMerchantsParams } from 'modules/merchants/store/merchants/interfaces/fetchMerchantsParams.interface';
import { useMerchantsColumns } from './hooks/useMerchantsColumns.hook';
import { filtersSchema } from './filters';

export const MerchantsPage: React.FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<FetchMerchantsParams>();
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [merchantIdForEditCommissions, setMerchantIdForEditCommissions] = useState<number>();
  const [merchantIdForBalances, setMerchantIdForBalances] = useState<number>();
  const [merchantIdForEdit, setMerchantIdForEdit] = useState<number>();
  const [merchantIdForTokens, setMerchantIdForTokens] = useState<number>();

  const { message } = App.useApp();

  const columns = useMerchantsColumns({
    onMerchantEdit: setMerchantIdForEdit,
    onMerchantCommissionEdit: setMerchantIdForEditCommissions,
    onMerchantDelete: handleDelete,
    onMerchantBalanceClick: setMerchantIdForBalances,
    onMerchantTokensEdit: setMerchantIdForTokens,
  });

  const {
    merchants,
    pagination,
    pagination: { perPage, page },
    isLoading,
  } = useAppSelector((state) => state.merchants);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetch(page);
  }, [page, perPage]);

  useEffect(() => {
    fetch(1);
  }, [memoFilters]);

  async function fetch(requestedPage = page) {
    try {
      await dispatch(fetchMerchants({ ...memoFilters, page: requestedPage, perPage }));
    } catch {
      message.error('Не удалось получить список клиентов. Попробуйте позже.');
    }
  }

  async function handleDelete(merchant: Merchant) {
    const messageKey = 'deleteMerchant_' + merchant.id;

    try {
      message.open({
        key: messageKey,
        type: 'loading',
        duration: 0,
        content: `Клиент ${merchant.name} находится в процессе удаления`,
      });

      await dispatch(deleteMerchant(merchant.id));

      message.open({
        key: messageKey,
        type: 'success',
        duration: 3,
        content: `Клиент ${merchant.name} удален`,
      });
    } catch {
      message.open({
        key: messageKey,
        type: 'error',
        duration: 3,
        content: 'Ошибка удаления клиента',
      });
    }
  }

  function handlePaginationChange(page: number, perPage: number) {
    dispatch(changePagination(page, perPage));
  }

  return (
    <>
      <CreateMerchantDrawer isShown={isAddModalVisible} onCancel={() => setIsAddModalVisible(false)} />
      <EditMerchantDrawer merchantId={merchantIdForEdit} onCancel={() => setMerchantIdForEdit(undefined)} />
      <EditMerchantCommissionsDrawer
        merchantId={merchantIdForEditCommissions}
        onCancel={() => setMerchantIdForEditCommissions(undefined)}
      />
      {merchantIdForBalances && (
        <MerchantBalancesDrawer
          merchantId={merchantIdForBalances}
          onClose={() => setMerchantIdForBalances(undefined)}
        />
      )}
      {merchantIdForTokens && (
        <MerchantTokensDrawer merchantId={merchantIdForTokens} onClose={() => setMerchantIdForTokens(undefined)} />
      )}
      <Filters
        headerTitle="Клиенты"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        extraHeaderButtons={() => (
          <ProtectedComponent requiredPermissions={[permissions.merchant.create]}>
            <Button
              className="ml-0"
              onClick={() => setIsAddModalVisible(true)}
              type="primary"
              shape="round"
              icon={<PlusCircleOutlined />}
            >
              Добавить
            </Button>
          </ProtectedComponent>
        )}
      />
      <Table
        columns={columns}
        dataSource={merchants}
        loading={isLoading}
        pagination={false}
        rowKey={(customer) => customer.id}
      />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
