import { DEFAULT_DATETIME_STRING_FORMAT } from 'constants/dates';
import React, { useState } from 'react';
import { Space, Drawer, Button, DatePicker, Select, Form, Spin, App } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { useMerchantsFromServer, useBanksFromServer, useCurrenciesFromServer } from 'hooks/useDataFromServer';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { OrdersCreateParams } from 'modules/orders/store/orders/interfaces/ordersCreateParams.interface';
import { createOrder } from 'modules/orders/store/orders/actions';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';
import { Input, InputNumber } from 'components/Input';
import { LazySelect } from 'components/LazySelect';
import { Tooltip } from 'components/Tooltip';

dayjs.extend(tz);
dayjs.extend(utc);

interface Props {
  isShown: boolean;
  onClose: () => void;
}

export const OrdersPageCreateDrawer: React.FC<Props> = ({ isShown, onClose }) => {
  const [isCreatingInProgress, setIsCreatingInProgress] = useState<boolean>(false);

  const [form] = useForm<Omit<OrdersCreateParams, 'timeOrder'> & { timeOrder: Dayjs }>();

  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  async function handleSumbit() {
    setIsCreatingInProgress(true);

    const values = form.getFieldsValue();

    const params: OrdersCreateParams = {
      ...values,
      timeOrder: values.timeOrder?.tz('Europe/Moscow', true).utc().format(DEFAULT_DATETIME_STRING_FORMAT),
    };

    try {
      await dispatch(createOrder(params));

      message.success('Транзакция успешно создана!');

      onClose();
    } catch {
      message.error('Не удалось создать транзакцию');
    }

    setIsCreatingInProgress(false);
  }

  function handleClose() {
    form.resetFields();
    onClose();
  }

  return (
    <Drawer title="Создать новую транзакцию" open={isShown} onClose={handleClose} destroyOnClose>
      <Spin spinning={isCreatingInProgress} tip="Идет создание транзакции..." size="large">
        <Form form={form} component={false}>
          <Space direction="vertical" size="middle">
            <Form.Item name="customerId" noStyle>
              <LazySelect
                placeholder="Клиент"
                className="w-full"
                useDataHook={useMerchantsFromServer}
                cacheKey="customer-filter"
              />
            </Form.Item>
            <Form.Item name="typeId" noStyle>
              <Select placeholder="Тип заявки" className="w-full">
                <Select.Option value={OrderTypes.OUTCOMING}>Исходящая</Select.Option>
                <Select.Option value={OrderTypes.INCOMING}>Входящая</Select.Option>
              </Select>
            </Form.Item>
            <InputNumber isAmountInput placeholder="Сумма" addonBefore="Сумма" className="w-full" name="amount" />
            <Form.Item name="currencyId" noStyle>
              <LazySelect
                placeholder="Валюта"
                className="w-full"
                useDataHook={useCurrenciesFromServer}
                cacheKey="currency-filter"
              />
            </Form.Item>
            <Form.Item name="providerId" noStyle>
              <LazySelect
                placeholder="Банк"
                className="w-full"
                useDataHook={useBanksFromServer}
                cacheKey="provider-select"
              />
            </Form.Item>
            <Tooltip title="Время заявки">
              <Form.Item name="timeOrder" noStyle>
                <DatePicker showTime placeholder="Время заявки" className="w-full" />
              </Form.Item>
            </Tooltip>
            <Input placeholder="Комментарий" addonBefore="Комментарий" className="w-full" name="comment" />
            <Button onClick={handleSumbit} className="w-full" type="primary">
              Создать транзакцию
            </Button>
          </Space>
        </Form>
      </Spin>
    </Drawer>
  );
};
