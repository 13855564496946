import { tableFiltersLocale } from 'constants/locale';
import { App, Button, Drawer, Form, Space, Spin, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useMemo, useState } from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';

import { updateMerchantCommissions } from 'modules/merchants/store/merchants/actions';
import { MerchantCommission } from 'modules/merchants/types/merchantCommission.interface';
import { fetchMerchantCommissionsRequest } from 'modules/merchants/api';
import { MerchantTariffTypesEnum } from 'modules/merchants/enums';
import { UpdateMerchantCommissionsParams } from 'modules/merchants/store/merchants/interfaces/updateMerchantCommissionsParams.type';
import { EditMerchantCommissionsDrawerModal } from './components/Modal';
import { EditMerchantCommissionsDrawerFilters } from './components/Filters';
import { useEditMerchantCommissionsGeneratedTableData } from './hooks/useEditMerchantCommissionsGeneratedTableData.hook';
import { TableMerchantCommission } from './types';
import { useEditMerchantCommissionsDrawerColumns } from './hooks/useEditMerchantCommissionsDrawerColumns.hook';

interface Props {
  merchantId: number | undefined;
  onCancel: () => void;
}

export const EditMerchantCommissionsDrawer: React.FC<Props> = ({ merchantId, onCancel }) => {
  const [isEditingInProgress, setIsEditingInProgress] = useState<boolean>(false);
  const [isEditCommissionsModalVisible, setIsEditCommissionsModalVisible] = useState<boolean>(false);
  const [isCommissionsLoading, setIsCommissionsLoading] = useState<boolean>(false);
  const [commissions, setCommissions] = useState<MerchantCommission[]>();
  const { merchants } = useAppSelector((state) => state.merchants);

  const [form] = useForm<{ [key: number]: TableMerchantCommission }>();

  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  const tableColumns = useEditMerchantCommissionsDrawerColumns();
  const fullTableData = useEditMerchantCommissionsGeneratedTableData({ commissions: commissions });

  const [selectedProviders, setSelectedProviders] = useState<number[]>([]);

  const storeCommissions = useMemo(() => {
    if (!merchantId) {
      return [];
    }

    const merchant = merchants.find((merchant) => merchantId === merchant.id);

    if (!merchant) {
      return [];
    }

    return commissions;
  }, [merchants]);

  const tableData = useMemo(() => {
    if (selectedProviders.length > 0) {
      const formValues = Object.values(form.getFieldsValue());
      const filteredValues = fullTableData.filter((el) => selectedProviders.includes(el.provider.id));

      return filteredValues.map((filteredCommission) => {
        const matchingFormCommission = formValues.find(
          (formCommission) => filteredCommission.provider.id === formCommission.provider.id,
        );

        return matchingFormCommission || filteredCommission;
      });
    }
    return fullTableData;
  }, [selectedProviders, fullTableData]);

  useEffect(() => {
    form.setFieldsValue(tableData);
  }, [tableData]);

  useEffect(() => {
    if (!merchantId) {
      return;
    }

    fetchMerchantCommissions();
  }, [merchantId]);

  useEffect(() => {
    setCommissions(storeCommissions);
  }, [storeCommissions]);

  function closeModal() {
    onCancel();
    setSelectedProviders([]);
    setCommissions([]);
  }

  async function fetchMerchantCommissions() {
    setIsCommissionsLoading(true);

    const data = await fetchMerchantCommissionsRequest(merchantId!);

    setCommissions(data);

    setIsCommissionsLoading(false);
  }

  function getFormCommissionsValues(): MerchantCommission[] {
    const commissions: MerchantCommission[] = [];

    let values = Object.values(form.getFieldsValue());

    if (selectedProviders.length > 0) {
      values = fullTableData.map((el) =>
        selectedProviders.includes(el.provider.id)
          ? values.filter((value) => value.provider.id == el.provider.id)[0]
          : el,
      );
    }

    values.forEach(({ provider, p2pIncomingValue, p2pOutcomingValue, c2cIncomingValue, c2cOutcomingValue }) => {
      if (p2pIncomingValue !== undefined || p2pOutcomingValue !== undefined) {
        commissions.push({
          bankId: provider.id,
          incomingValue: (p2pIncomingValue ?? 0) / 100,
          outcomingValue: (p2pOutcomingValue ?? 0) / 100,
          tariffType: MerchantTariffTypesEnum.P2P,
        });
      }

      if (c2cIncomingValue !== undefined || c2cOutcomingValue !== undefined) {
        commissions.push({
          bankId: provider.id,
          incomingValue: (c2cIncomingValue ?? 0) / 100,
          outcomingValue: (c2cOutcomingValue ?? 0) / 100,
          tariffType: MerchantTariffTypesEnum.C2C,
        });
      }
    });

    return commissions;
  }

  async function onHandleSubmit() {
    if (!merchantId) {
      return;
    }

    setIsEditingInProgress(true);

    const commissions: UpdateMerchantCommissionsParams = getFormCommissionsValues();

    const isSuccess = await dispatch(
      updateMerchantCommissions({
        commissions,
        merchantId,
      }),
    );

    setIsEditingInProgress(false);

    if (!isSuccess) {
      message.error('Не удалось изменить комиссии клиента.');

      return;
    }

    message.success('Клиент успешно изменен');

    closeModal();
  }

  return (
    <>
      <Drawer
        extra={
          <Space>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                setCommissions(getFormCommissionsValues());
                setIsEditCommissionsModalVisible(true);
              }}
            >
              Массовое заполнение комиссии
            </Button>
          </Space>
        }
        size="large"
        title="Редактирование коммисий"
        open={!!merchantId}
        onClose={closeModal}
      >
        <Spin tip="Комиссии обновляются..." spinning={isEditingInProgress} size="large">
          <Form form={form}>
            <EditMerchantCommissionsDrawerFilters
              selectedFilters={selectedProviders}
              onSelectedFilterChange={setSelectedProviders}
            />
            <Table
              size="small"
              columns={tableColumns}
              dataSource={tableData}
              locale={tableFiltersLocale}
              pagination={false}
              loading={isCommissionsLoading}
            />
          </Form>
          <Button type="primary" className="w-full mt-5" onClick={onHandleSubmit}>
            Сохранить
          </Button>
        </Spin>
      </Drawer>
      <EditMerchantCommissionsDrawerModal
        isVisible={isEditCommissionsModalVisible}
        merchantId={merchantId!}
        commissions={commissions}
        updateCommissions={setCommissions}
        onCancel={() => setIsEditCommissionsModalVisible(false)}
        onClose={() => setIsEditCommissionsModalVisible(false)}
      />
    </>
  );
};
