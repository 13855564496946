import React, { FC } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { App, Button } from 'antd';
import { useRequestPaymentsReportRequest } from 'modules/payments/hooks/useRequestPaymentsReportRequest.hook';
import { RequestPaymentsReportRequestParams } from 'modules/payments/api/requestPaymentsReport.request';

interface Props {
  params: RequestPaymentsReportRequestParams | (() => RequestPaymentsReportRequestParams);
}

export const RequestPaymentsReportButton: FC<Props> = ({ params }) => {
  const { requestPaymentsReport, isRequestingPaymentReport } = useRequestPaymentsReportRequest();

  const { message, notification } = App.useApp();

  async function handleClick() {
    const reportParams = typeof params === 'function' ? params() : params;

    try {
      await requestPaymentsReport(reportParams);

      notification.success({
        message: 'Отчет формируется и будет доступен во вкладке "Отчеты"',
        duration: 3,
      });
    } catch {
      message.error('Не удалось сформировать отчет');
    }
  }

  return (
    <Button
      className="bg-green-600 border-green-600 hover:bg-green-500"
      type="default"
      icon={<DownloadOutlined />}
      onClick={handleClick}
      style={{ color: 'white', border: 'none' }}
      loading={isRequestingPaymentReport}
      disabled={isRequestingPaymentReport}
    >
      Скачать .xlsx
    </Button>
  );
};
