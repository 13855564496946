import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';
import Link from 'antd/lib/typography/Link';
import telegramIcon from 'assets/svg/telegram.svg';
import { OrderDisputesPageTableReceiptsColumn } from './components/ReceiptsColumn';
import { OrderDisputesPageTableStatusColumn } from './components/StatusColumn';

import { OrderDisputesPageTableActionsColumn } from './components/ActionsColumn';
import { OrderDisputesPageTableExecutionDurationColumn } from './components/ExecutionDurationColumn';

dayjs.extend(utc);

export function useOrderDisputesTableColumns(
  timeElapsedFromMount: number,
  onShowSmsModal: (dispute: IOrderDispute) => void,
) {
  const now = dayjs.utc();

  return useMemo<ColumnsType<IOrderDispute>>(() => {
    return [
      { key: 'id', dataIndex: 'id', title: 'Номер диспута' },
      {
        key: 'status',
        title: 'Статус',
        render: (_: any, orderDispute: IOrderDispute) => (
          <OrderDisputesPageTableStatusColumn orderDispute={orderDispute} />
        ),
      },
      {
        key: 'executionDuration',
        title: 'Время обработки',
        render: (_, dispute: IOrderDispute) => <OrderDisputesPageTableExecutionDurationColumn dispute={dispute} />,
      },
      {
        key: 'transactionId',
        dataIndex: 'transactionId',
        title: 'ID Заявки',
        render: (transactionId: number) => (
          <a style={{ color: '#008ecc' }} href={'/transactions_static?id=' + transactionId}>
            {transactionId}
          </a>
        ),
      },
      {
        key: 'customerTransactionId',
        title: 'ID заявки клиента',
        width: 170,
        render: (_, dispute: IOrderDispute) => {
          return <span className="break-all">{dispute.customerName + '_' + dispute.customerTransactionId}</span>;
        },
      },
      {
        key: 'owners',
        align: 'center',
        title: 'Чат владельца',
        render: (_: any, dispute: IOrderDispute) => {
          if (dispute.ownerChatTgMessageLink) {
            // todo переделать свг на img тег
            return (
              <Link
                className="flex justify-center gap-1"
                style={{ color: '#008ecc' }}
                target="_blank"
                href={dispute.ownerChatTgMessageLink}
              >
                {dispute.ownerId}
                <img src={telegramIcon} alt="telegram" />
              </Link>
            );
          }

          return dispute.ownerId ?? 'Нет данных';
        },
      },
      {
        key: 'paymentAmount',
        title: 'Переведенная сумма',
        render: (_, dispute: IOrderDispute) =>
          dispute.firstPaymentAmount
            ? (dispute.firstPaymentAmount + (dispute.secondPaymentAmount ?? 0)).toLocaleString('ru-RU')
            : '',
      },
      {
        key: 'receiverCardNumber',
        title: 'Карта получателя',
        render: (_, dispute: IOrderDispute) => <span className="break-all">{dispute.cardNumber}</span>,
      },
      {
        key: 'comment',
        dataIndex: 'comment',
        title: 'Комментарий',
      },
      {
        key: 'receipts',
        title: 'Чеки',
        width: '140px',
        render: (_, dispute: IOrderDispute) => <OrderDisputesPageTableReceiptsColumn dispute={dispute} />,
      },
      {
        key: 'actions',
        render: (_, dispute: IOrderDispute) => (
          <OrderDisputesPageTableActionsColumn onShowSmsModal={onShowSmsModal} dispute={dispute} />
        ),
      },
    ];
  }, [timeElapsedFromMount, now]);
}
