import React, { ReactElement } from 'react';
import axios, { AxiosError, type AxiosRequestConfig } from 'axios';
import { notification } from 'antd';

import { PRESERVED_USER_TOKEN_KEY } from 'modules/auth/store/auth/reducers';
import { ValidationErrorResponse } from 'types/validationErrorResponse.interface';
import { ErrorResponse } from 'types/errorResponse.interface';

const baseRequestConfig: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_URL + '/api/admin',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  xsrfHeaderName: 'X-XSRF-TOKEN',
  withCredentials: true,
};

const instance = axios.create(baseRequestConfig);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    let description: string | ReactElement = String(error);

    if (error?.response?.data) {
      let response = error.response.data as ValidationErrorResponse | ErrorResponse | Blob;

      if (response instanceof Blob) {
        response = JSON.parse(await response.text()) as ValidationErrorResponse | ErrorResponse;
      }

      description = response.message;

      if ('errors' in response) {
        description = (
          <>
            <b>{response.message}</b>
            {Object.values(response.errors).map((errorItem) => (
              <>
                <br />
                {errorItem.join(', ')}
              </>
            ))}
          </>
        );
      }
    }

    notification.error({
      message: 'Ошибка!',
      description,
      duration: 6,
    });

    throw error;
  },
);

instance.interceptors.request.use((config) => {
  config.headers.setAuthorization(`Bearer ${localStorage.getItem(PRESERVED_USER_TOKEN_KEY)}`);

  return config;
});

export const api = instance;

// eslint-disable-next-line import/no-default-export
export default instance;
