import React, { useEffect, useState, useMemo } from 'react';
import { Space, Input, Spin, Typography, Button, Alert, Checkbox, Select, Card, App } from 'antd';

import {
  IConfigurationValue,
  ConfigurationValueTypes,
  ConfigurationValueValueType,
} from 'modules/configuration/store/types';
import { fetchConfiguration, updateConfiguration } from 'modules/configuration/store/actions';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { useDataFromServer } from 'hooks/useDataFromServer';
import { permissions } from 'policies/permissions';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { InputNumber } from 'components/Input';
import { LazySelect } from 'components/LazySelect';

export const ConfigurationPage: React.FC = () => {
  const [changedValues, setChangedValues] = useState<{
    [key: string]: ConfigurationValueValueType;
  }>({});

  const { message } = App.useApp();

  const { isLoading, configuration } = useAppSelector((state) => state.configuration);

  const dispatch = useAppDispatch();

  const form = useMemo(() => generateForm(configuration), [configuration, changedValues]);
  const hasChangedValues = useMemo<boolean>(() => !!Object.values(changedValues).length, [changedValues]);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    try {
      await dispatch(fetchConfiguration());
    } catch {
      message.error('Не удалось обновить конфигурацию');
    }
  }

  function generateForm(
    configuration: {
      [key: string]: IConfigurationValue;
    },
    prefix: string = '',
    depth: number = 0,
  ) {
    const resultView = [];

    for (const key in configuration) {
      const { value, type, title, children, available_values_list } = configuration[key];
      const keyOfItem = [prefix, key].filter(Boolean).join('.');

      let inputComponent;

      if (!value && children) {
        resultView.push(
          <Card key={keyOfItem} className="rounded mt-3 mb-3">
            <Typography.Title level={3} className="mb-6">
              {title}
            </Typography.Title>
            {generateForm(children, keyOfItem, depth + 1)}
          </Card>,
        );

        continue;
      }

      switch (type) {
        case ConfigurationValueTypes.STRING:
          inputComponent = (
            <Input defaultValue={value as string} onChange={(event) => onChange(keyOfItem, event.target.value)} />
          );
          break;
        case ConfigurationValueTypes.FLOAT:
        case ConfigurationValueTypes.INT:
          inputComponent = (
            <InputNumber
              defaultValue={value as number}
              isAmountInput
              onChange={(newValue) => onChange(keyOfItem, newValue ?? 0)}
            />
          );
          break;
        case ConfigurationValueTypes.BOOL:
          inputComponent = (
            <Checkbox
              defaultChecked={value as boolean}
              onChange={(event) => onChange(keyOfItem, event.target.checked)}
            />
          );
          break;
        case ConfigurationValueTypes.ARRAY: {
          const currentValue = (changedValues[keyOfItem] ?? value) as Array<string>;

          if (available_values_list) {
            inputComponent = (
              <LazySelect
                style={{ minWidth: 400 }}
                mode="tags"
                dataTransformer={(value) => ({ value, label: value })}
                dataFilter={(value, index, self) => index === self.findIndex((t) => t.value === value.value)}
                useDataHook={(autoFetch: boolean) =>
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  useDataFromServer({
                    endpoint: `/config/values/${keyOfItem}`,
                    defaultValue: [],
                    autoFetch,
                  })
                }
                cacheKey={keyOfItem}
                value={currentValue}
                onChange={(newValue) => onChange(keyOfItem, newValue)}
              />
            );
          } else {
            inputComponent = (
              <Select
                style={{ minWidth: 400 }}
                mode="tags"
                value={currentValue}
                onChange={(newValue) => onChange(keyOfItem, newValue)}
                options={currentValue.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            );
          }

          break;
        }
        default:
          continue;
      }

      resultView.push(
        <Space key={keyOfItem}>
          <div>{title}:</div>
          {inputComponent}
        </Space>,
      );
    }

    return (
      <Space direction="vertical" className={`pl-${depth * 2}`}>
        {resultView}
      </Space>
    );
  }

  function onChange(key: string, value: ConfigurationValueValueType) {
    setChangedValues((state) => ({ ...state, [key]: value }));
  }

  async function onSubmit() {
    const payload = [];

    for (const key in changedValues) {
      payload.push({ key, value: changedValues[key] });
    }

    try {
      await dispatch(updateConfiguration(payload));
    } catch {
      message.error('Не удалось обновить конфигурацию');
    }

    setChangedValues({});
  }

  return (
    <>
      <Spin spinning={isLoading} className="w-full" wrapperClassName="w-full">
        <Typography.Title>Конфигурация системы</Typography.Title>
        {hasChangedValues && <Alert type="info" message="У вас есть несохраненные изменения" />}
        {form}

        {hasChangedValues && (
          <ProtectedComponent requiredPermissions={[permissions.config.update]}>
            <Space className="w-full pt-4" direction="vertical">
              <Button type="primary" onClick={onSubmit}>
                Сохранить изменения
              </Button>
            </Space>
          </ProtectedComponent>
        )}
      </Spin>
    </>
  );
};
