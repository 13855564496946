import React, { FC, useEffect } from 'react';

import { App, Table } from 'antd';

import { Filters } from 'components/Filters';
import { Pagination } from 'components/Pagination';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';

import { fetchBankTransactions } from 'modules/bankTransactions/store';

import { FetchBankTransactionsParams } from 'modules/bankTransactions/store/types/fetchBankTransactionsParams.interface';
import { filtersSchema } from './filters';

import { useBankTransactionsColumns } from './hooks/useBankTransactionsColumns.hook';
import { BankTransactionsPageReportButton } from './components/ReportButton';

export const BankTransactionsPage: FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<FetchBankTransactionsParams>();

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  const columns = useBankTransactionsColumns();

  const {
    bankTransactions,
    pagination,
    pagination: { perPage, page },
    isLoading,
  } = useAppSelector((state) => state.bankTransactions);

  useEffect(() => {
    fetch(memoFilters, page, perPage);
  }, [page, perPage]);

  useEffect(() => {
    fetch(memoFilters, 1, perPage);
  }, [memoFilters]);

  async function fetch(memoFilters: FetchBankTransactionsParams, page: number, perPage: number) {
    try {
      await dispatch(fetchBankTransactions({ ...memoFilters, page, perPage })).unwrap();
    } catch {
      message.error('Не удалось получить список банков');
    }
  }

  function handlePaginationChange(page: number, perPage: number): void {
    fetch(memoFilters, page, perPage);
  }

  return (
    <>
      <Filters
        headerTitle="Банковские транзакции"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        customButtons={(getFilters) => <BankTransactionsPageReportButton getFilters={getFilters} />}
      />
      <Table
        columns={columns}
        dataSource={bankTransactions}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.id}
      />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
