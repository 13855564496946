import { Agent } from 'modules/agents/types/agent.interface';
import React from 'react';
import { Button, Space, Table } from 'antd';
import { CrownOutlined, EditOutlined } from '@ant-design/icons';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { Tooltip } from 'components/Tooltip';
import { ColumnsType } from 'antd/lib/table';

interface IMakeColumnsConfig {
  onEditPress: (agent: Agent) => void;
}

export function makeColumns({ onEditPress }: IMakeColumnsConfig): ColumnsType<Agent> {
  return [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Наименование агента',
      key: 'name',
      dataIndex: 'name',
      align: 'center',
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Рефералы',
      dataIndex: 'referralsCount',
      key: 'referralsCount',
      align: 'left',
    },
    {
      title: 'Владельцы',
      key: 'parentId',
      align: 'center',
      render: (_, agent) => {
        const count = agent.ownersCount ?? 0;

        const content = (
          <>
            <CrownOutlined /> Владельцы ({count})
          </>
        );

        if (count > 0) {
          return (
            <a className="text-primaryColor" href={`/owners?agentIds=%5B${agent.id}%5D`}>
              {content}
            </a>
          );
        }

        return <p className="text-gray-500">{content}</p>;
      },
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      align: 'center',
      render: (_, agent) => (
        <Space size="small">
          <ProtectedComponent requiredPermissions={[permissions.agent.update]}>
            <Tooltip title="Редактировать агента">
              <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => onEditPress(agent)} />
            </Tooltip>
          </ProtectedComponent>
        </Space>
      ),
    },
  ];
}
