import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { App, Button, Form, Modal, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useBanksFromServer, useCurrenciesFromServer } from 'hooks/useDataFromServer';

import { CurrencyNameEnum } from 'enums/CurrencyEnum';

import { UpdateMerchantCommissionsParams } from 'modules/merchants/store/merchants/interfaces/updateMerchantCommissionsParams.type';
import { MerchantCommission } from 'modules/merchants/types';
import { MerchantTariffTypesEnum } from 'modules/merchants/enums';
import { CommissionColumn, TableMerchantModalCommission } from '../../types';
import { useEditMerchantCommissionsDrawerModalColumns } from './hooks/useEditMerchantCommissionsDrawerModalColumns';

interface Props {
  isVisible: boolean;
  merchantId: number;
  commissions: MerchantCommission[] | undefined;
  updateCommissions: (commissions: MerchantCommission[]) => void;
  onCancel: () => void;
  onClose: () => void;
}

const emptyCommissionRow = {
  c2cIncomingValue: undefined,
  c2cOutcomingValue: undefined,
  p2pIncomingValue: undefined,
  p2pOutcomingValue: undefined,
  currencyCode: '',
} as TableMerchantModalCommission;

export const EditMerchantCommissionsDrawerModal: React.FC<Props> = ({
  isVisible,
  onCancel,
  onClose,
  commissions,
  updateCommissions,
}) => {
  const [form] = useForm<{ [key: number]: TableMerchantModalCommission }>();

  const [providers] = useBanksFromServer();
  const [currencies] = useCurrenciesFromServer();

  const [tableData, setTableData] = useState([emptyCommissionRow]);
  const [isEditingInProgress, setIsEditingInProgress] = useState<boolean>(false);
  const [isAddRowButtonVisible, setIsAddRowButtonVisible] = useState<boolean>(true);

  const { message } = App.useApp();

  const columns = useEditMerchantCommissionsDrawerModalColumns(
    currencies,
    tableData,
    onCurrencySelect,
    onCommissionChange,
    handleRemoveRowByIndex,
  );

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    form.setFieldsValue(tableData);
  }, [tableData, isVisible]);

  function onCurrencySelect(currency: string, index: number) {
    if (currency === 'RUB') {
      tableData[index] = { ...tableData.at(index)!, currencyCode: currency };
    } else {
      tableData[index] = {
        ...tableData.at(index)!,
        currencyCode: currency,
        c2cIncomingValue: undefined!,
        c2cOutcomingValue: undefined!,
      };
    }
    setTableData([...tableData]);
  }

  function onCommissionChange(commission: number | undefined, index: number, commissionName: CommissionColumn) {
    tableData[index] = { ...tableData.at(index)!, [commissionName]: commission };

    setTableData([...tableData]);
  }

  function addNewRow() {
    setTableData([...tableData, emptyCommissionRow]);

    if (tableData.length === currencies.length - 1) {
      setIsAddRowButtonVisible(false);
    }
  }

  function handleRemoveRowByIndex(index: number) {
    if (tableData.length > 1) {
      const updatedTableData = [...tableData];

      updatedTableData.splice(index, 1);
      setTableData(updatedTableData);
    } else {
      message.error('Невозможно удалить последний элемент.');
    }

    setIsAddRowButtonVisible(true);
  }

  async function handleUpdate() {
    const commissionsToUpload: UpdateMerchantCommissionsParams = [];
    let prevCommission = commissions;

    setIsEditingInProgress(true);

    tableData.forEach(({ p2pIncomingValue, p2pOutcomingValue, c2cIncomingValue, c2cOutcomingValue, currencyCode }) => {
      providers.forEach((provider) => {
        if (provider.currencyCode === currencyCode) {
          prevCommission = prevCommission?.filter((el) => el.bankId !== provider.id);

          if (p2pIncomingValue !== undefined || p2pOutcomingValue !== undefined) {
            commissionsToUpload.push({
              bankId: provider.id,
              incomingValue: (p2pIncomingValue ?? 0) / 100,
              outcomingValue: (p2pOutcomingValue ?? 0) / 100,
              tariffType: MerchantTariffTypesEnum.P2P,
            });
          } else {
            const currentCommission = commissions?.find(
              (el) => el.bankId == provider.id && el.tariffType === MerchantTariffTypesEnum.P2P,
            );

            commissionsToUpload.push({
              bankId: provider.id,
              incomingValue: currentCommission && currentCommission.incomingValue,
              outcomingValue: currentCommission && currentCommission.outcomingValue,
              tariffType: MerchantTariffTypesEnum.P2P,
            });
          }

          if (c2cIncomingValue !== undefined || c2cOutcomingValue !== undefined) {
            commissionsToUpload.push({
              bankId: provider.id,
              incomingValue: (c2cIncomingValue ?? 0) / 100,
              outcomingValue: (c2cOutcomingValue ?? 0) / 100,
              tariffType: MerchantTariffTypesEnum.C2C,
            });
          } else {
            if (provider.currencyCode === CurrencyNameEnum.RUB) {
              const currentCommission = commissions?.find(
                (el) => el.bankId == provider.id && el.tariffType === MerchantTariffTypesEnum.C2C,
              );

              if (currentCommission) {
                commissionsToUpload.push({
                  bankId: provider.id,
                  incomingValue: currentCommission && currentCommission.incomingValue,
                  outcomingValue: currentCommission && currentCommission.outcomingValue,
                  tariffType: MerchantTariffTypesEnum.C2C,
                });
              }
            }
          }
        }
      });
    });

    if (!tableData.filter((el) => el.currencyCode === '').length) {
      prevCommission?.forEach((commission) => {
        commissionsToUpload.push({
          bankId: commission.bankId,
          incomingValue: commission.incomingValue,
          outcomingValue: commission.outcomingValue,
          tariffType: commission.tariffType,
        });
      });

      updateCommissions(commissionsToUpload);
      onCancel();
      onClose();

      message.success('Комиссии по валютам обновлены.');
    } else {
      message.error('Не выбрана валюта, пожалуйста, выберете валюту.');
    }

    setIsEditingInProgress(false);
  }

  return (
    <Modal
      footer={
        <Button key="submit" type="primary" shape="round" loading={isEditingInProgress} onClick={() => handleUpdate()}>
          Обновить
        </Button>
      }
      title="Массовое заполнение комиссии"
      open={isVisible}
      onCancel={onCancel}
      width={750}
      mask={false}
      afterOpenChange={(isOpen) => isOpen && setTableData([emptyCommissionRow])}
    >
      <Form form={form}>
        <Table size="small" columns={columns} dataSource={tableData} pagination={false} />
      </Form>
      <Button
        type="text"
        icon={<PlusOutlined />}
        hidden={!isAddRowButtonVisible}
        className="mt-2"
        onClick={addNewRow}
      />
    </Modal>
  );
};
