import React, { useEffect, useState } from 'react';
import { Badge } from 'antd';
import instance from 'utils/axios';
import { useChannel } from 'hooks/useChannel.hook';

export const LayoutSidebarDisputesCounter: React.FC = () => {
  const [count, setCount] = useState<number>();

  useEffect(() => {
    fetchCount();
  }, []);

  useChannel('private-disputes', {
    '.created': () => {
      setCount((state) => (state || 0) + 1);
    },
  });

  async function fetchCount() {
    const {
      data: { data },
    } = await instance.get('/disputes/active_count');

    setCount(data);
  }

  return <Badge count={count} />;
};
