import React, { useState } from 'react';
import { Divider, Modal } from 'antd';
import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';
import { OwnersSMSPage } from 'modules/owners/pages/sms';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { changePagination } from 'modules/owners/store/ownersSms/actions';
import { useOnResize } from 'hooks/useOnResize';
import { OrderDisputesPageSmsModalHeader } from './components/Header';

interface Props {
  dispute?: IOrderDispute;
  onCancel: () => void;
}

export const OrderDisputesPageSmsModal: React.FC<Props> = ({ dispute, onCancel }) => {
  const [width, setWidth] = useState<number>(70);

  const dispatch = useAppDispatch();

  useOnResize(() => {
    const screenWidth = window.innerWidth;

    if (screenWidth > 1200) return setWidth(70);

    if (screenWidth > 768) return setWidth(80);

    setWidth(95);
  });

  function handlePaginationReset() {
    dispatch(changePagination(1, 20));
  }

  return (
    <Modal
      open={!!dispute}
      width={`${width}%`}
      onCancel={() => {
        onCancel();
        handlePaginationReset();
      }}
      destroyOnClose
      footer
    >
      <div className="flex flex-col w-full">
        {dispute && (
          <div>
            <OrderDisputesPageSmsModalHeader dispute={dispute} />
          </div>
        )}
        <Divider />
        <OwnersSMSPage dispute={dispute} embedded />
      </div>
    </Modal>
  );
};
