import React from 'react';
import { type InputNumberProps, InputNumber as BaseInput, Form } from 'antd';
import { type InternalNamePath } from 'antd/es/form/interface';

interface Props extends Omit<InputNumberProps, 'name'> {
  name?: string | number | InternalNamePath;
  noStyle?: boolean;
  isAmountInput?: boolean;
}

export const InputNumber: React.FC<Props> = ({ name, noStyle = true, isAmountInput, ...props }) => {
  const baseInput = (
    <BaseInput
      {...props}
      formatter={
        isAmountInput
          ? (value) => {
              if (value === undefined || value === '') {
                return '';
              }

              return Number(value).toLocaleString('ru-RU');
            }
          : undefined
      }
      decimalSeparator=","
    />
  );

  if (!name) return baseInput;

  return (
    <Form.Item name={name} noStyle={noStyle}>
      {baseInput}
    </Form.Item>
  );
};
