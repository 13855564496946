import React, { useMemo, useEffect, useState } from 'react';
import { type SelectProps } from 'antd';

import { UseDataFromServerHookStatesEnum } from 'hooks/useDataFromServer';

import { type DefaultOptionType } from 'antd/es/select';
import { LazySelectDataTransformer } from 'components/LazySelect/types/dataTransformer.type';
import { LazySelectOptions } from 'components/LazySelect/types/options.type';
import { Select } from 'components/Select';

interface Props extends SelectProps {
  cacheKey: string;
  fetchIfNoCache?: boolean;
  useDataHook: (autoFetch: boolean) => readonly [any, UseDataFromServerHookStatesEnum, () => Promise<void>];
  dataTransformer?: LazySelectDataTransformer;
  dataFilter?: (data: any, index: number, array: any[]) => boolean;
}

const alreadyLoadingOptions: { [cacheKey: string]: boolean } = {};
const eventNamePrefix = 'onLazySelectOptionsFetched';

export const LazySelect: React.FC<Props> = ({
  useDataHook,
  dataTransformer = (item: { name: string; id: number }) => ({ label: item.name, value: item.id }),
  cacheKey,
  fetchIfNoCache = true,
  defaultValue,
  dataFilter,
  ...selectProps
}) => {
  const [data, state, fetchData] = useDataHook(false);

  const [receivedState, setReceivedState] = useState<LazySelectOptions>([]);
  const [selectedItems, setSelectedItems] = useState<string | string[]>(defaultValue || []);

  const rawOptions = useMemo(() => {
    if (state === UseDataFromServerHookStatesEnum.INITIALIZED) {
      const data = readOptionsCache() ?? receivedState;

      if (dataFilter) {
        return data.filter(dataFilter);
      }

      return data;
    }

    if (!data) {
      return receivedState;
    }

    const generatedOptions: LazySelectOptions = data.map(dataTransformer);

    document.dispatchEvent(new CustomEvent(eventName(), { detail: generatedOptions }));

    saveOptionsCache(generatedOptions);

    if (dataFilter) {
      return generatedOptions.filter(dataFilter);
    }

    return generatedOptions;
  }, [data, dataTransformer, state, receivedState, dataFilter]);

  const options = useMemo(() => {
    if (!Array.isArray(selectedItems) || selectedItems.length === 0 || selectProps.mode === undefined) {
      return rawOptions;
    }

    const selectedOptions = rawOptions.filter((option: { value: string }) => selectedItems.includes(option.value));
    const unselectedOptions = rawOptions.filter((option: { value: string }) => !selectedItems.includes(option.value));

    return [...selectedOptions, ...unselectedOptions];
  }, [rawOptions, selectedItems]);

  function handleChange(value: string | string[], options: DefaultOptionType | DefaultOptionType[]) {
    setSelectedItems(Array.isArray(value) ? value : [value]);

    selectProps.onChange?.(value, options);
  }

  useEffect(() => {
    if (options.length !== 0 || !fetchIfNoCache || state !== UseDataFromServerHookStatesEnum.INITIALIZED) return;

    fetchOptions();
  }, [options, fetchIfNoCache, state]);

  async function fetchOptions(force: boolean = false): Promise<void> {
    if (!loadingIsActive() || force) {
      setLoadingIsActive();

      await fetchData();

      return;
    }

    document.addEventListener(eventName(), handleOptionsFetchedEvent);
  }

  function handleOptionsFetchedEvent(event: Event) {
    const { detail: options } = event as CustomEvent;

    setReceivedState(options);

    document.removeEventListener(eventName(), handleOptionsFetchedEvent);
  }

  function eventName(): string {
    return eventNamePrefix + '_' + cacheKey;
  }

  function loadingIsActive(): boolean {
    return alreadyLoadingOptions[cacheKey];
  }

  function setLoadingIsActive(): void {
    alreadyLoadingOptions[cacheKey] = true;
  }

  function handleFocus(): void {
    if (state !== UseDataFromServerHookStatesEnum.INITIALIZED) {
      return;
    }

    fetchOptions(true);
  }

  function readOptionsCache() {
    const cachedOptions = localStorage.getItem(getCacheKey());

    if (!cachedOptions) {
      return null;
    }

    return JSON.parse(cachedOptions);
  }

  function saveOptionsCache(optionsToCache: any) {
    localStorage.setItem(getCacheKey(), JSON.stringify(optionsToCache));
  }

  function getCacheKey() {
    return 'lazy_select_options_cache_' + cacheKey;
  }

  return (
    <Select
      {...selectProps}
      options={options}
      loading={state === UseDataFromServerHookStatesEnum.LOADING}
      status={state === UseDataFromServerHookStatesEnum.FAILURE ? 'error' : undefined}
      onFocus={handleFocus}
      onChange={handleChange}
    />
  );
};
