import instance from 'utils/axios';
import { MerchantBalance } from 'modules/merchants/types';

export async function fetchMerchantBalancesRequest(merchantId: number, perPage: number): Promise<MerchantBalance[]> {
  const {
    data: { data },
  } = await instance.get('/merchants/balances/list', { params: { merchantId, perPage } });

  return data;
}
