import React, { useEffect } from 'react';
import { Form, Button, Spin, App } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useRolesFromServer } from 'hooks/useDataFromServer';
import { Input } from 'components/Input';
import { LazySelect } from 'components/LazySelect';
import { useFetchUserRequest } from 'modules/users/hooks/useFetchUserRequest.hook';
import { useUpdateUserRequest } from 'modules/users/hooks/useUpdateUserRequest.hook';
import { UpdateUserRequestParams } from 'modules/users/api/updateUser.request';

interface Props {
  userId: number;
  isRolesEditable?: boolean;
  onSubmit?: () => void;
}

export const UpdateUserForm: React.FC<Props> = ({ userId, isRolesEditable = true, onSubmit }) => {
  const { user, isFetchingUser } = useFetchUserRequest(userId);
  const { updateUser, isUpdatingUser } = useUpdateUserRequest();

  const [form] = useForm<UpdateUserRequestParams>();

  const { message } = App.useApp();

  useEffect(() => {
    if (!user) {
      return;
    }

    form.setFieldsValue({ ...user, roles: user.roles.map((role) => role.id) });
  }, [user]);

  async function handleSubmit() {
    if (!user) return;

    const values = form.getFieldsValue();

    try {
      await updateUser(values);
    } catch {
      message.error('Не удалось обновить пользователя');
    }

    message.success('Пользователь успешно обновлен!');

    onSubmit?.();
  }

  if (!user) return null;

  return (
    <Spin spinning={isUpdatingUser || isFetchingUser}>
      <Form form={form} initialValues={user ?? {}}>
        <Form.Item hidden name="id" />
        <Input className="mb-3" name="name" addonBefore="Имя" />
        <Input className="mb-3" name="email" addonBefore="Почта" />
        <Input
          className="mb-3"
          name="telegramUsername"
          addonBefore="Ник Telegram"
          type="text"
          autoComplete="one-time-code"
        />
        <Input className="mb-3" name="newPassword" addonBefore="Новый пароль" type="password" />
        <Form.Item hidden={!isRolesEditable} name="roles" label="Роли">
          <LazySelect mode="multiple" useDataHook={useRolesFromServer} fetchIfNoCache cacheKey="roles-select" />
        </Form.Item>
      </Form>
      <Button type="primary" onClick={handleSubmit}>
        Сохранить
      </Button>
    </Spin>
  );
};
