import React, { useState } from 'react';
import { App, Button } from 'antd';

import instance from 'utils/axios';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';

import { FetchRejectedTransactionsParams } from 'modules/rejectedTransactions/store/types/fetchRejectedTransactionsParams.interface';
import { DownloadOutlined } from '@ant-design/icons';

interface Props {
  getFilters: () => FetchRejectedTransactionsParams;
}

export const RejectedTransactionsPageReportButton: React.FC<Props> = ({ getFilters }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { message } = App.useApp();

  async function exportInExcel(filters: FetchRejectedTransactionsParams) {
    try {
      setIsLoading(true);

      await instance.post('/rejected-orders/report', sanitizeRequestParams(filters));

      message.success('Отчет формируется и будет доступен во вкладке "Отчеты"');
    } catch {
      message.error('Не удалось скачать банковские транзакции');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button
      className="bg-green-600 border-green-600 hover:bg-green-500"
      type="default"
      icon={<DownloadOutlined />}
      onClick={() => exportInExcel(getFilters())}
      style={{ color: 'white', border: 'none' }}
      loading={isLoading}
    >
      Скачать в .xlsx
    </Button>
  );
};
