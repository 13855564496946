import React, { useEffect, useState } from 'react';

import { App, Drawer } from 'antd';

import { IFetchOwnersParams, OwnersActionTypes } from 'modules/owners/store/owners/types';
import {
  changePage,
  deleteOwner,
  updateOwner,
  fetchMoreOwners,
  fetchOwners,
} from 'modules/owners/store/owners/actions';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';

import { IOwner } from 'modules/owners/types/IOwner';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useLazyTable } from 'hooks/useLazyTable.hook';
import { useColumns } from 'modules/owners/pages/list/columns';
import { OwnersPageFilter } from 'modules/owners/pages/list/components/Filters';
import { LazyTable } from 'components/LazyTable';
import { OwnerBankAccountsList } from 'modules/owners/components/OwnerBankAccountsList/OwnerBankAccountsList';
import { OwnerPageEditOwnerBalanceDrawer } from './components/EditOwnerBalanceDrawer';
import { OwnersPageSmsHistoryExportDrawer } from './components/ExportDrawer';
import { OwnersPageAssignToCustomerDrawer } from './components/AssignToCustomerDrawer';
import { OwnersPageEditOwnerModal } from './components/EditOwnerModal';
import { OwnersPageCreateDrawer } from './components/CreateOwnerModal';

export const OwnersPage: React.FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<IFetchOwnersParams>();

  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [selectedForAssignOwner, setSelectedForAssignOwner] = useState<IOwner>();
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [editModalValue, setEditModalValue] = useState<IOwner>({} as IOwner);
  const [bankAccountsListOwner, setBankAccountsListOwner] = useState<IOwner>();
  const [editOwnerBalance, setEditOwnerBalance] = useState<IOwner>();
  const [isExportDrawerShown, setIsExportDrawerShown] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFetchOwnersParams>(memoFilters);

  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  const [props, { isLoading, setIsTableView, isTableView }] = useLazyTable({
    store: 'owners',
    dataField: 'owners',
    fetchAction: fetchOwners,
    loadMoreAction: fetchMoreOwners,
    changePageAction: changePage,
    resetAction: () => ({ type: OwnersActionTypes.CLEAR_OWNERS }),
    filters: memoFilters,
  });

  const columns = useColumns({
    onAssignToCustomerPress: (owner) => setSelectedForAssignOwner(owner),
    onCardPress: (owner) => {
      setBankAccountsListOwner(owner);
    },
    onEditPress: (owner) => {
      setEditModalValue(owner);
      setIsEditModalVisible(true);
    },
    onDeletePress: handleDeletePress,
  });

  useEffect(() => {
    setFilters(memoFilters);
  }, [memoFilters]);

  function onClearGoipCascader(): void {
    setFilters({} as IFetchOwnersParams);
  }

  function handleFiltersSubmit(value: IFetchOwnersParams): void {
    // TODO: Костыль для отчистки каскадера, пока не приведем все на нормальный вид
    if (Object.values(value).length === 0) {
      setMemoFilters(value);
      setFilters(value);

      return;
    }

    setMemoFilters({ ...filters, ...value });
  }

  async function handleEditModalSubmit(): Promise<void> {
    const loadingMessageKey = 'editOwnerLoading_' + JSON.stringify(editModalValue);

    if (Object.keys(editModalValue).length === 0) return;

    try {
      message.open({
        key: loadingMessageKey,
        type: 'loading',
        content: `Обновление владельца №${editModalValue.ownerId}...`,
        duration: 2,
      });

      delete editModalValue.bankId;
      delete editModalValue.accountNumber;

      await dispatch(updateOwner(editModalValue));

      message.open({
        content: `Владелец №${editModalValue.ownerId} обновлен!`,
        key: loadingMessageKey,
        type: 'success',
        duration: 3,
      });

      setEditModalValue({} as IOwner);
      setIsEditModalVisible(false);
    } catch {
      message.error('Не удалось обновить владельца');
    }
  }

  async function handleDeletePress(ownerId: number) {
    try {
      await dispatch(deleteOwner(ownerId));

      message.success('Владелец успешно удален');
    } catch {
      message.error('Не удалось удалить владельца');
    }
  }

  return (
    <>
      <OwnersPageCreateDrawer isShown={isAddModalVisible} onCancel={() => setIsAddModalVisible(false)} />
      <OwnersPageEditOwnerModal
        isShown={isEditModalVisible}
        params={editModalValue}
        onChange={setEditModalValue}
        onSubmit={handleEditModalSubmit}
        onCancel={() => setIsEditModalVisible(false)}
      />
      <Drawer
        title={'Счета и карты владельца ' + bankAccountsListOwner?.fio}
        open={!!bankAccountsListOwner}
        onClose={() => setBankAccountsListOwner(undefined)}
        width={650}
      >
        {bankAccountsListOwner && <OwnerBankAccountsList ownerId={bankAccountsListOwner.ownerId} />}
      </Drawer>
      <OwnersPageAssignToCustomerDrawer
        owner={selectedForAssignOwner}
        onClose={() => setSelectedForAssignOwner(undefined)}
      />
      <OwnersPageSmsHistoryExportDrawer isShown={isExportDrawerShown} onClose={() => setIsExportDrawerShown(false)} />
      <OwnerPageEditOwnerBalanceDrawer owner={editOwnerBalance} onClose={() => setEditOwnerBalance(undefined)} />
      <OwnersPageFilter
        currentFilters={filters}
        submittedFilters={memoFilters}
        onSubmit={handleFiltersSubmit}
        onClear={onClearGoipCascader}
        onAddPress={() => setIsAddModalVisible(true)}
        isLoading={isLoading}
        isTableView={isTableView}
        onViewToggleChanged={setIsTableView}
        onChange={(values) => setFilters(values)}
        onExport={() => setIsExportDrawerShown(true)}
      />
      <LazyTable columns={columns} {...props} />
    </>
  );
};
