import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Divider, Typography, message, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useTheme } from 'contexts';

interface Props {
  transactionAmount: number;
  ownersTotalSum: number;
}

export const AssignOwnersToTransactionsFormTotalSumCounter: React.FC<Props> = ({
  transactionAmount,
  ownersTotalSum,
}) => {
  const isSuccess = ownersTotalSum === transactionAmount;

  const {
    currentTheme: { token },
  } = useTheme();

  const { colorTextLight } = token ?? {};

  function handleCopyPress(): void {
    if (isSuccess) {
      navigator.clipboard.writeText(String(ownersTotalSum));
    } else {
      navigator.clipboard.writeText(String(transactionAmount - ownersTotalSum));
    }

    message.success('Скопировано!');
  }

  return (
    <>
      <Divider />
      <div className="text-gray-500 opacity-80 mb-2" style={{ color: colorTextLight }}>
        Сумма для отправки
      </div>
      <Typography.Text
        className={twMerge([isSuccess ? 'text-green-600' : 'text-red-600', 'text-2xl', 'items-center', 'flex'])}
      >
        {isSuccess
          ? 'Сумма для отправки: ' + Number(ownersTotalSum).toLocaleString('ru-RU')
          : 'Осталось: ' + Number(transactionAmount - ownersTotalSum).toLocaleString('ru-RU')}
        <Tooltip title="Скопировать">
          <CopyOutlined
            className="hover:text-blue-600 cursor-pointer text-sm leading-none ml-2"
            style={{ color: colorTextLight }}
            onClick={handleCopyPress}
          />
        </Tooltip>
      </Typography.Text>
      <Divider />
    </>
  );
};
