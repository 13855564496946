import instance from 'utils/axios';

import {
  IFetchOrderDisputesParams,
  IFetchOrderDisputesReducerAction,
  OrderDisputesActionTypes,
  ISetIsLoadingOrderDisputesReducerActions,
  IChangePaginationOrderDisputesReducerActions,
  IUpdateOrderDisputeStatusReducerActions,
  IAddOrderDisputeReducerAction,
  ISetBufferingOrderDisputeReducerAction,
} from 'modules/disputes/store/orderDisputes/types';
import { OrderDisputesStatusesEnum } from 'modules/disputes/types/OrderDisputeStatusesEnum';
import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { AppDispatch } from 'app/store';

export function fetchOrderDisputes(params: IFetchOrderDisputesParams) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoading(true));

      const reqParams = sanitizeRequestParams(params, []);

      const {
        data: { data, meta },
      } = await instance.get('/disputes', { params: reqParams });

      dispatch<IFetchOrderDisputesReducerAction>({
        type: OrderDisputesActionTypes.FETCH_ORDER_DISPUTES,
        payload: { pagination: meta, orderDisputes: data },
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function updateOrderDisputeStatus(orderDisputeId: number, newStatus: OrderDisputesStatusesEnum) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { data },
      } = await instance.post('/disputes/update_status', {
        orderDisputeId,
        newStatus,
      });

      dispatch<IUpdateOrderDisputeStatusReducerActions>({
        type: OrderDisputesActionTypes.UPDATE_STATUS,
        payload: data as IOrderDispute,
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function changePagination(page: number, perPage: number) {
  return (dispatch: AppDispatch) => {
    dispatch<IChangePaginationOrderDisputesReducerActions>({
      type: OrderDisputesActionTypes.CHANGE_PAGINATION,
      payload: { page, perPage },
    });
  };
}

export function addOrderDispute(newOrderDispute: IOrderDispute) {
  return (dispatch: AppDispatch) => {
    dispatch<IAddOrderDisputeReducerAction>({
      type: OrderDisputesActionTypes.ADD,
      payload: newOrderDispute,
    });
  };
}

export function setIsLoading(payload: boolean) {
  return (dispatch: AppDispatch) => {
    dispatch<ISetIsLoadingOrderDisputesReducerActions>({
      type: OrderDisputesActionTypes.SET_IS_LOADING,
      payload,
    });
  };
}

export function setBuffering(payload: boolean) {
  return (dispatch: AppDispatch) => {
    dispatch<ISetBufferingOrderDisputeReducerAction>({
      type: OrderDisputesActionTypes.SET_BUFFERING,
      payload,
    });
  };
}
