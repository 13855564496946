import React, { FC, useEffect } from 'react';
import { App, Button, Form, Image, Spin, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { Input } from 'components/Input';

import { useFetchBankRequest, useUpdateBankRequest } from 'modules/banks/hooks';
import { UpdateBankRequestParams } from 'modules/banks/api';
import { UpdateBankFormItem } from 'modules/banks/components/UpdateBankForm/components/Item/UpdateBankFormItem';

interface Props {
  bankId: number;
  onSubmit: () => void;
}

export const UpdateBankForm: FC<Props> = ({ bankId, onSubmit }) => {
  const [form] = useForm<UpdateBankRequestParams>();

  const { bank, isFetchingBank } = useFetchBankRequest(bankId);
  const { isUpdatingBank, updateBank } = useUpdateBankRequest();

  const { message } = App.useApp();

  useEffect(() => {
    if (!bank) return;

    form.setFieldsValue(bank);
  }, [bank]);

  async function handleSubmit(): Promise<void> {
    try {
      await updateBank(form.getFieldsValue());

      message.success('Банк успешно изменён!');
      onSubmit();
    } catch {
      message.error('Не удалось обновить банк!');
    }
  }

  if (!bank) {
    return null;
  }

  return (
    <Spin spinning={isUpdatingBank || isFetchingBank}>
      <Form form={form} key={JSON.stringify(bank)}>
        <UpdateBankFormItem label="ID Банка" name="id">
          <Input size="middle" className="w-full" disabled />
        </UpdateBankFormItem>
        <UpdateBankFormItem label="Название банка" name="name">
          <Input size="middle" className="w-full" />
        </UpdateBankFormItem>
        <UpdateBankFormItem label="Название банка (англ.)" name="nameEn">
          <Input size="middle" className="w-full" />
        </UpdateBankFormItem>
        <UpdateBankFormItem label="Внеший / внутренний банк">
          {bank.internal ? (
            <Tooltip title="Внутренний банк">
              <CheckOutlined className="text-green-700" />
            </Tooltip>
          ) : (
            <Tooltip title="Внешний банк">
              <CloseOutlined className="text-red-700" />
            </Tooltip>
          )}
        </UpdateBankFormItem>
        <UpdateBankFormItem label="Код страны" name="countryCode">
          <Input size="middle" className="w-full" />
        </UpdateBankFormItem>
        <UpdateBankFormItem label="Код валюты" name="currencyCode">
          <Input size="middle" className="w-full" />
        </UpdateBankFormItem>
        <UpdateBankFormItem
          label="Главная картинка"
          name="imageUrl"
          after={bank.imageUrl && <Image src={bank.imageUrl} width={60} alt={bank.name} preview={false} />}
        >
          <Input size="middle" className="mr-2" />
        </UpdateBankFormItem>
        <UpdateBankFormItem
          label="Маленький логотип"
          name="logoSmallSizeUrl"
          after={
            bank.logoSmallSizeUrl && <Image src={bank.logoSmallSizeUrl} width={60} alt={bank.name} preview={false} />
          }
        >
          <Input size="middle" className="mr-2" />
        </UpdateBankFormItem>
        <UpdateBankFormItem
          label="Крупный логотип"
          name="logoBigSizeUrl"
          after={bank.logoBigSizeUrl && <Image src={bank.logoBigSizeUrl} width={60} alt={bank.name} preview={false} />}
        >
          <Input size="middle" className="mr-2" />
        </UpdateBankFormItem>
        <UpdateBankFormItem
          label="Инвертированный большой логотип"
          name="logoInvertBigSizeUrl"
          after={
            bank.logoInvertBigSizeUrl && (
              <Image
                src={bank.logoInvertBigSizeUrl}
                width={60}
                className="bg-black flex items-center"
                alt={bank.name}
                preview={false}
              />
            )
          }
        >
          <Input size="middle" className="mr-2" />
        </UpdateBankFormItem>
        <UpdateBankFormItem
          label="Инвертированный маленький логотип"
          name="logoInvertSmallSizeUrl"
          after={
            bank.logoInvertSmallSizeUrl && (
              <Image
                src={bank.logoInvertSmallSizeUrl}
                width={60}
                className="bg-black flex items-center"
                alt={bank.name}
                preview={false}
              />
            )
          }
        >
          <Input size="middle" className="mr-2" />
        </UpdateBankFormItem>
        <UpdateBankFormItem label="Цвет фона">
          <ul className="w-full">
            {bank?.backgroundColors?.map((_, index) => (
              <li key={index} className="mb-2 flex">
                <Form.Item name={['backgroundColors', index]} noStyle>
                  <Input size="middle" className="w-20 max-h-10 min-h-10 mr-1" />
                </Form.Item>
                <Form.Item name={['backgroundColors', index]} noStyle>
                  <Input type="color" size="middle" className="w-20 min-h-10 max-h-10 mr-1" />
                </Form.Item>
              </li>
            ))}
          </ul>
        </UpdateBankFormItem>
        <Button type="primary" onClick={handleSubmit}>
          Сохранить
        </Button>
      </Form>
    </Spin>
  );
};
