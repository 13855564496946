import React, { useEffect, useState } from 'react';

import { useForm } from 'antd/es/form/Form';
import { PlusOutlined } from '@ant-design/icons';
import { App, Button, Drawer, Form, Input, Space, Spin } from 'antd';

import { UpdateUserForm } from 'modules/users/components/UpdateUserForm/UpdateUserForm';
import { CreateUserForm } from 'modules/users/components/CreateUserForm/CreateUserForm';

import { useUpdateAgentRequest } from 'modules/agents/hooks/useUpdateAgentRequest.hook';
import { useFetchAgentRequest } from 'modules/agents/hooks/useFetchAgentRequest.hook';
import { useFetchUsersRequest } from 'modules/users/hooks/useFetchUsersRequest.hook';
import { UserCard } from 'modules/users/components/UserCard';
import { UpdateAgentRequestParams } from 'modules/agents/api/updateAgent.request';

interface Props {
  onSubmit: () => void;
  agentId: number;
}

export const UpdateAgentForm: React.FC<Props> = ({ onSubmit, agentId }) => {
  const [form] = useForm<UpdateAgentRequestParams>();

  const [updateUserId, setUpdateUserId] = useState<number>();
  const [isCreateUserDrawerShown, setIsCreateUserDrawerShown] = useState<boolean>(false);

  const { updateAgent, isAgentUpdating } = useUpdateAgentRequest();
  const { isFetchingAgent, agent } = useFetchAgentRequest(agentId);
  const { users = [], isFetchingUsers, fetchUsers } = useFetchUsersRequest({ agentId });

  const { message } = App.useApp();

  useEffect(() => {
    if (!agent) return;

    form.setFieldsValue({ ...agent });
  }, [agent]);

  async function handleSubmitPress(): Promise<void> {
    try {
      const params = form.getFieldsValue();

      await updateAgent({
        id: agent!.id,
        name: params.name,
        parentId: agent!.parentId,
      });

      message.success('Агент успешно обновлен');
      form.resetFields();
      onSubmit();
    } catch {
      message.error('Не удалось обновить агента.');
    }
  }

  return (
    <>
      <Spin
        tip={isFetchingUsers ? '' : 'Сохранение изменений'}
        size="large"
        spinning={isFetchingAgent || isFetchingUsers || isAgentUpdating}
      >
        <Form form={form}>
          <Space className="w-full" direction="vertical" size="large">
            <Form.Item className="mb-0" name="name">
              <Input placeholder="Наименование агента" addonBefore="Наименование" className="w-full" />
            </Form.Item>
            <div className="py-2 text-center text-lg font-semibold">Пользователи</div>
            <div className="flex flex-col gap-y-2">
              {!isFetchingUsers &&
                users.map((user) => (
                  <UserCard key={user.id} user={user} onUpdateClick={({ id }) => setUpdateUserId(id)} />
                ))}
            </div>
            <div className="w-full flex justify-center">
              <Button
                type="dashed"
                onClick={() => setIsCreateUserDrawerShown(true)}
                style={{ width: '100%' }}
                icon={<PlusOutlined />}
              >
                Добавить пользователя
              </Button>
            </div>
            <Button onClick={handleSubmitPress} className="w-full" type="primary">
              Сохранить изменения
            </Button>
          </Space>
        </Form>
      </Spin>
      <Drawer
        open={!!updateUserId}
        onClose={() => setUpdateUserId(undefined)}
        title={`Редактирование пользователя ${updateUserId}`}
      >
        {updateUserId && (
          <UpdateUserForm
            isRolesEditable={false}
            userId={updateUserId}
            onSubmit={() => {
              setUpdateUserId(undefined);

              fetchUsers();
            }}
          />
        )}
      </Drawer>
      <Drawer
        open={isCreateUserDrawerShown}
        onClose={() => setIsCreateUserDrawerShown(false)}
        title="Создать пользователя агента"
      >
        <CreateUserForm
          agentId={agent?.id}
          isRolesEditable={false}
          onSubmit={() => {
            setIsCreateUserDrawerShown(false);

            fetchUsers();
          }}
        />
      </Drawer>
    </>
  );
};
