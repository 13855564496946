import {
  IOrderDisputeState,
  OrderDisputesReducerActions,
  OrderDisputesActionTypes,
  IFetchOrderDisputesPayload,
  IChangePaginationOrderDisputesPayload,
} from 'modules/disputes/store/orderDisputes/types';
import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';

const initialState: IOrderDisputeState = {
  orderDisputes: [],
  isLoading: false,
  pagination: {
    page: 0,
    perPage: 20,
    total: 0,
    pages: 1,
  },
  isBuffering: false,
  buffer: [],
};

export function orderDisputesReducer(state = initialState, action: OrderDisputesReducerActions): IOrderDisputeState {
  switch (action.type) {
    case OrderDisputesActionTypes.FETCH_ORDER_DISPUTES: {
      const { orderDisputes, pagination } = action.payload as IFetchOrderDisputesPayload;

      return { ...state, orderDisputes, pagination };
    }
    case OrderDisputesActionTypes.SET_IS_LOADING: {
      const isLoading = action.payload as boolean;

      return { ...state, isLoading };
    }
    case OrderDisputesActionTypes.CHANGE_PAGINATION: {
      const { page, perPage } = action.payload as IChangePaginationOrderDisputesPayload;

      return {
        ...state,
        pagination: { ...state.pagination, page, perPage },
        buffer: [],
      };
    }
    case OrderDisputesActionTypes.UPDATE_STATUS: {
      const updatedDispute = action.payload as IOrderDispute;

      return {
        ...state,
        orderDisputes: state.orderDisputes.map((dispute) =>
          dispute.id === updatedDispute.id ? updatedDispute : dispute,
        ),
        buffer: state.buffer.map((dispute) => (dispute.id === updatedDispute.id ? updatedDispute : dispute)),
      };
    }
    case OrderDisputesActionTypes.ADD: {
      const newOrderDispute = action.payload as IOrderDispute;

      if (state.pagination.page !== 1) return state;

      if (state.isBuffering) {
        return {
          ...state,
          buffer: [newOrderDispute, ...state.orderDisputes],
        };
      }

      return {
        ...state,
        orderDisputes: [newOrderDispute, ...state.orderDisputes],
      };
    }
    case OrderDisputesActionTypes.SET_BUFFERING:
      if (action.payload === state.isBuffering) return state;

      if (action.payload) {
        return { ...state, isBuffering: true };
      } else {
        return {
          ...state,
          isBuffering: false,
          buffer: [],
          orderDisputes: [...state.buffer, ...state.orderDisputes],
        };
      }
    default:
      return state;
  }
}
