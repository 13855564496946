import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { App, Button, Form, Modal, Table } from 'antd';
import { FormInstance, useForm } from 'antd/lib/form/Form';

import { useBanksFromServer, useCurrenciesFromServer } from 'hooks/useDataFromServer';

import { emptyCommissionRow } from 'modules/merchants/constants';

import type { ValueType } from '@rc-component/mini-decimal';

import { MerchantCommissionStep, MerchantCommissionStepModal } from 'modules/merchants/types';
import { useMerchantCommissionsModalColumns } from './hooks/useMerchantCommissionsModalColumns.hook';

interface Props {
  isVisible: boolean;
  onCancel: () => void;
  updateTable: (updatedTableData: MerchantCommissionStepModal[]) => void;
  commissionsForm: FormInstance<{
    [bankId: number]: {
      bankId: number;
      incomingValue: { c2c: number; p2p: number };
      outcomingValue: { c2c: number; p2p: number };
      currencyCode?: string;
    };
  }>;
}

export const CreateMerchantDrawerCommissionsStepModal: React.FC<Props> = ({
  isVisible,
  onCancel,
  updateTable,
  commissionsForm,
}) => {
  const [form] = useForm<{ [key: number]: MerchantCommissionStep }>();

  const [providers] = useBanksFromServer();
  const [currencies] = useCurrenciesFromServer();

  const [tableData, setTableData] = useState([emptyCommissionRow]);
  const [isEditingInProgress, setIsEditingInProgress] = useState<boolean>(false);
  const [isAddRowButtonVisible, setIsAddRowButtonVisible] = useState<boolean>(true);

  const { message } = App.useApp();

  const tableColumns = useMerchantCommissionsModalColumns(
    currencies,
    tableData,
    onCurrencySelect,
    onCommissionChange,
    handleRemoveRowByIndex,
  );

  useEffect(() => {
    form.setFieldsValue(tableData);
  }, [tableData]);

  function onCurrencySelect(currency: string, index: number) {
    if (currency === 'RUB') {
      tableData[index] = { ...tableData[index]!, currencyCode: currency };
    } else {
      tableData[index] = {
        ...tableData[index]!,
        currencyCode: currency,
        incomingValue: { c2c: undefined!, p2p: tableData[index].incomingValue.p2p },
        outcomingValue: { c2c: undefined!, p2p: tableData[index].outcomingValue.p2p },
      };
    }
    setTableData([...tableData]);
  }

  function onCommissionChange(
    commission: ValueType | null,
    index: number,
    commissionName: 'incomingValue' | 'outcomingValue',
    commissionType: 'p2p' | 'c2c',
  ) {
    const normalizedCommission = commission === null ? undefined : Number(commission);

    tableData[index] = {
      ...tableData[index]!,
      [commissionName]: { ...tableData[index]?.[commissionName], [commissionType]: normalizedCommission },
    };

    setTableData([...tableData]);
  }

  function addNewRow() {
    setTableData([...tableData, emptyCommissionRow]);

    setIsAddRowButtonVisible(tableData.length !== currencies.length - 1);
  }

  function handleRemoveRowByIndex(index: number) {
    setIsAddRowButtonVisible(true);

    if (tableData.length <= 1) {
      message.error('Невозможно удалить последний элемент.');

      return;
    }

    const updatedTableData = [...tableData];

    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  }

  function handleUpdate() {
    const commissionsToUpdate: MerchantCommissionStepModal[] = [];

    setIsEditingInProgress(true);

    const commissionsData = commissionsForm.getFieldsValue();

    if (!tableData.filter((el) => el.currencyCode === '').length) {
      Object.values(commissionsData).forEach(({ bankId, currencyCode, incomingValue, outcomingValue }) => {
        const providerCurrencyCode = providers.find((el) => el.id === bankId)?.currencyCode;
        const commission = tableData.find((el) => el.currencyCode == providerCurrencyCode);

        if (commission && commission.currencyCode === providerCurrencyCode) {
          commissionsToUpdate[bankId] = {
            bankId,
            incomingValue: {
              c2c: commission.incomingValue.c2c ? commission.incomingValue.c2c : incomingValue.c2c,
              p2p: commission.incomingValue.p2p ? commission.incomingValue.p2p : incomingValue.p2p,
            },
            outcomingValue: {
              c2c: commission.outcomingValue.c2c ? commission.outcomingValue.c2c : outcomingValue.c2c,
              p2p: commission.outcomingValue.p2p ? commission.outcomingValue.p2p : outcomingValue.p2p,
            },
            currencyCode: commission.currencyCode,
          };
        } else {
          commissionsToUpdate[bankId] = {
            bankId,
            incomingValue: { c2c: incomingValue.c2c, p2p: incomingValue.p2p },
            outcomingValue: { c2c: outcomingValue.c2c, p2p: outcomingValue.p2p },
            currencyCode: currencyCode!,
          };
        }
      });

      updateTable(commissionsToUpdate);
      onCancel();
    } else {
      message.error('Не выбрана валюта, пожалуйста, выберете валюту.');
    }

    setIsEditingInProgress(false);
  }

  return (
    <Modal
      footer={
        <>
          <Button type="default" shape="round" onClick={() => setTableData([emptyCommissionRow])}>
            Очистить
          </Button>
          <Button
            key="submit"
            type="primary"
            shape="round"
            loading={isEditingInProgress}
            onClick={() => handleUpdate()}
          >
            Обновить
          </Button>
        </>
      }
      title="Массовое заполнение комиссии"
      open={isVisible}
      onCancel={onCancel}
      width={750}
      mask={false}
    >
      <Form form={form}>
        <Table
          size="small"
          columns={tableColumns}
          dataSource={tableData}
          pagination={false}
          rowKey={(record) => record.currencyCode}
        />
      </Form>
      <Button
        type="text"
        icon={<PlusOutlined />}
        hidden={!isAddRowButtonVisible}
        className="mt-2"
        onClick={addNewRow}
      />
    </Modal>
  );
};
