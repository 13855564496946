import useSWRMutation from 'swr/mutation';
import { requestPaymentsReportRequest, RequestPaymentsReportRequestParams } from 'modules/payments/api';

export function useRequestPaymentsReportRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'request-payments-report-request',
    (_, { arg }: { arg: RequestPaymentsReportRequestParams }) => requestPaymentsReportRequest(arg),
    {
      throwOnError: true,
    },
  );

  return { requestPaymentsReport: trigger, isRequestingPaymentReport: isMutating };
}
