import useSWRMutation from 'swr/mutation';
import { disableOutcomingBankAccountRequest } from 'modules/bankAccounts/api/disableOutcomingBankAccount.request';

export function useDisableOutcomingBankAccountRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'disable-outcoming-bank-account',
    (
      _,
      {
        arg,
      }: {
        arg: number;
      },
    ) => disableOutcomingBankAccountRequest(arg),
  );

  return {
    disableOutcomingBankAccount: trigger,
    isDisablingOutcomingBankAccount: isMutating,
  };
}
