import React, { useState } from 'react';

import { useForm } from 'antd/lib/form/Form';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Button, Form, Space, Spin } from 'antd';

import { Input } from 'components/Input';
import { LazySelect } from 'components/LazySelect';

import { useAgentsFromServer } from 'hooks/useDataFromServer';
import { useTheme } from 'contexts';
import { useCreateAgentRequest } from 'modules/agents/hooks/useCreateAgentRequest.hook';
import { CreateAgentRequestParams } from 'modules/agents/api/createAgent.request';

interface Props {
  onSubmit: () => void;
}

export const CreateAgentForm: React.FC<Props> = ({ onSubmit }) => {
  const [agentUsers, setAgentUser] = useState<number[]>([1]);

  const { createAgent, isCreatingAgent } = useCreateAgentRequest();

  const [form] = useForm<CreateAgentRequestParams>();

  const { message } = App.useApp();

  const {
    currentTheme: { token },
  } = useTheme();

  const { colorBgLabelInput, colorBorderInput, colorTextLight } = token ?? {};

  function addNewRow() {
    setAgentUser([...agentUsers, 1]);
  }

  async function handleSubmitPress() {
    try {
      await createAgent(form.getFieldsValue());

      message.success('Агент успешно создан');
      onSubmit();
    } catch {
      message.error('Не удалось создать агента.');
    }
  }

  function removeUserByIndex(index: number) {
    if (agentUsers.length <= 1) {
      message.error('У агента должен быть как минимум один пользователь');

      return;
    }

    const updatedUsers = [...agentUsers];

    updatedUsers.splice(index, 1);

    setAgentUser(updatedUsers);
  }

  return (
    <Spin tip="Идет создание агента..." spinning={isCreatingAgent} size="large">
      <Form form={form} component={false}>
        <Space className="w-full" direction="vertical" size="middle">
          <div className="flex items-center">
            <div
              className="px-2 py-1 border border-solid whitespace-nowrap rounded-l-md"
              style={{
                backgroundColor: colorBgLabelInput,
                borderColor: colorBorderInput,
                color: colorTextLight,
              }}
            >
              Реферал
            </div>
            <Form.Item name="parentId" noStyle>
              <LazySelect
                allowClear
                placeholder="Наименование реферала"
                className="w-full"
                useDataHook={useAgentsFromServer}
                cacheKey="agents-select"
              />
            </Form.Item>
          </div>
          <Input placeholder="Наименование агента" addonBefore="Наименование" className="w-full" name="name" />
          <div className="py-2 text-center text-lg font-semibold">Пользователи</div>
          {agentUsers.map((_, index) => (
            <div
              key={index}
              className="flex flex-row rounded-xl shadow-lg border-t-2 border-green-500 p-4 gap-x-2 pr-2 items-center"
            >
              <div className="flex flex-col">
                <Form.Item className="mb-2" name={['users', index, 'name']}>
                  <Input placeholder="Имя" addonBefore="Имя" className="w-full" />
                </Form.Item>
                <Form.Item className="mb-2" name={['users', index, 'email']}>
                  <Input placeholder="Email" addonBefore="Email" className="w-full" />
                </Form.Item>
                <Form.Item className="mb-2" name={['users', index, 'password']}>
                  <Input placeholder="Пароль" addonBefore="Пароль" className="w-full" />
                </Form.Item>
                <Form.Item className="mb-0" name={['users', index, 'telegramUsername']}>
                  <Input placeholder="Ник Telegram" addonBefore="Ник Telegram" className="w-full" />
                </Form.Item>
              </div>
              <div className="text-right">
                <Button
                  danger
                  type="text"
                  icon={<DeleteOutlined />}
                  className="mt-2"
                  onClick={() => {
                    removeUserByIndex(index);
                  }}
                />
              </div>
            </div>
          ))}
          <div className="w-full flex justify-center">
            <Button type="dashed" onClick={addNewRow} style={{ width: '100%' }} icon={<PlusOutlined />}>
              Добавить пользователя
            </Button>
          </div>
          <Button htmlType="submit" onClick={handleSubmitPress} className="w-full" type="primary">
            Создать
          </Button>
        </Space>
      </Form>
    </Spin>
  );
};
