import useSWRMutation from 'swr/mutation';
import { disableIncomingBankAccountRequest } from 'modules/bankAccounts/api/disableIncomingBankAccount.request';

export function useDisableIncomingBankAccountRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'disable-incoming-bank-account',
    (
      _,
      {
        arg,
      }: {
        arg: number;
      },
    ) => disableIncomingBankAccountRequest(arg),
  );

  return {
    disableIncomingBankAccount: trigger,
    isDisablingIncomingBankAccount: isMutating,
  };
}
