import useSWRMutation from 'swr/mutation';
import { setReportsAsSeenRequest } from 'modules/reports/api';

export function useSetReportsAsSeenRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'set-reports-as-seen',
    (_, { arg }: { arg: number[] }) => setReportsAsSeenRequest(arg),
    {
      throwOnError: true,
    },
  );

  return { setReportsAsSeen: trigger, isSettingReportsAsSeen: isMutating };
}
