import React, { useState, useEffect } from 'react';
import { Drawer, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import instance from 'utils/axios';

import { IOwner } from 'modules/owners/types/IOwner';
import { BackendResponse } from 'types/BackendResponse';
import { Merchant } from 'modules/merchants/types/merchant.interface';
import {
  OwnerToCustomerBindings,
  IOwnerBindingsResponse,
  ILinksWithCommissions,
  IBindOwnerParams,
  IRow,
} from './types';

import { OwnersPageAssignToCustomerDrawerForm } from './components/Form';

interface Props {
  owner?: IOwner;
  onClose: () => void;
}

export const OwnersPageAssignToCustomerDrawer: React.FC<Props> = ({ owner, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agentsBranch, setAgentsBranch] = useState<IOwner[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);

  const [form] = useForm<OwnerToCustomerBindings>();

  useEffect(() => {
    if (!owner) return;

    fetchBindingsData();
  }, [owner]);

  async function fetchBindingsData(): Promise<void> {
    try {
      setIsLoading(true);

      const allCustomers = await fetchMerchants();
      const { linksWithCommissions, agentsBranch } = await fetchOwnerBindings();

      const fields: OwnerToCustomerBindings = {};

      allCustomers.forEach((customer, index) => {
        const field: ILinksWithCommissions = {
          customer,
          agentsCommissions: agentsBranch.map(({ ownerId: agentId }, id) => ({
            ownerId: owner?.ownerId ?? 0,
            agentId,
            id,
            value: null,
            customerId: customer.id,
          })),
          ownerCommission: {
            id: index,
            ownerId: owner?.ownerId ?? 0,
            customerId: customer.id,
            value: null,
          },
          isActive: false,
        };

        const existCustomerBinding = linksWithCommissions.find((link) => link.customer.id === customer.id);

        if (!existCustomerBinding) {
          fields[customer.id] = field;

          return;
        }

        field.isActive = existCustomerBinding.isActive;

        if (existCustomerBinding.ownerCommission) {
          field.ownerCommission = existCustomerBinding.ownerCommission;

          if (field.ownerCommission.value) {
            field.ownerCommission.value *= 100;
          }
        }

        field.agentsCommissions = field.agentsCommissions.map((agentCommission) => {
          const existAgentCommission = existCustomerBinding.agentsCommissions.find(
            (commission) => commission.agentId === agentCommission.agentId,
          );

          if (!existAgentCommission) return agentCommission;

          if (existAgentCommission.value) {
            existAgentCommission.value *= 100;
          }

          return existAgentCommission;
        });

        fields[customer.id] = field;
      });

      form.setFieldsValue(fields);
      processRows(fields);

      setAgentsBranch(agentsBranch);
    } catch {
      onClose();
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchOwnerBindings(): Promise<IOwnerBindingsResponse> {
    const {
      data: { data },
    } = await instance.get<BackendResponse<IOwnerBindingsResponse>>(`/owners/${owner?.ownerId}/merchants`);

    return data;
  }

  async function fetchMerchants(): Promise<Merchant[]> {
    const {
      data: { data },
    } = await instance.get<BackendResponse<Merchant[]>>('/merchants/list-short?withDeleted=0', {
      params: { withDeleted: 0 },
    });

    return data;
  }

  async function handleSubmit(merchantId: number, createBind: boolean): Promise<void> {
    if (!owner) return;

    const value: ILinksWithCommissions = form.getFieldValue(merchantId);

    try {
      setIsLoading(true);

      const { agentsCommissions } = value;

      const params: IBindOwnerParams = {
        ownerId: owner.ownerId,
        merchantId,
        ownerCommission: 0.001,
        agentsCommissions: agentsCommissions.map(({ agentId }) => ({
          agentId,
          value: 0.001,
        })),
      };

      if (createBind) {
        await instance.post('/owners/merchants', params);
      } else {
        await instance.delete('/owners/merchants', { params });
      }
    } catch {
      form.setFieldValue(merchantId, {
        ...value,
        isActive: !value.isActive,
      });
      processRows();

      message.error('Не удалось привязать/отвязать владельца к клиенту');
    } finally {
      setIsLoading(false);
    }
  }

  function processRows(formFields?: OwnerToCustomerBindings): void {
    const fields = formFields || rows.map(({ customer: { id } }) => form.getFieldValue(id));

    const updatedRows = Object.values(fields).map((field: ILinksWithCommissions) => {
      //TODO: временно не требуется проверка
      // const ownersCommissionsLimit = field.customer.ownersCommissionsLimit * 100;
      // const commissionsSum =
      //   (field.ownerCommission.value ?? 0) + field.agentsCommissions.reduce((a, b) => a + (b.value ?? 0), 0);

      return {
        customer: field.customer,
        //TODO: временно не требуется проверка, ставим флаг, чтобы легко вернуть ее обратно
        //  commissionsExceeded: commissionsSum > ownersCommissionsLimit,
        commissionsExceeded: false,
        disabled: field.isActive,
      };
    });

    setRows(updatedRows);
  }

  return (
    <Drawer
      title={`Привязать владельца №${owner?.ownerId} к клиенту`}
      open={!!owner}
      onClose={onClose}
      width="max-content"
    >
      <OwnersPageAssignToCustomerDrawerForm
        form={form}
        agents={agentsBranch}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        onChange={processRows}
        rows={rows}
      />
    </Drawer>
  );
};
