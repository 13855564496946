import React, { useMemo } from 'react';
import { ColumnType } from 'antd/lib/table';
import { Button, Space, Tooltip } from 'antd';
import { EditOutlined, LinkOutlined } from '@ant-design/icons';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { Partner } from 'modules/partners/types/partner.interface';
import { PartnersPageEnabledSwitch } from '../components/EnabledSwitch';

interface Params {
  onUpdateClick: (partner: Partner) => void;
  onViewMerchantsClick: (partner: Partner) => void;
}

export function usePartnersColumns({ onUpdateClick, onViewMerchantsClick }: Params): ColumnType<Partner>[] {
  return useMemo(
    () => [
      {
        title: 'Имя',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Статус',
        key: 'enabled',
        dataIndex: 'enabled',
        render: (_, partner) => <PartnersPageEnabledSwitch partner={partner} />,
      },
      {
        title: 'Лимит доверия',
        key: 'trustLimit',
        dataIndex: 'trustLimit',
      },
      {
        title: 'Действия',
        key: 'actions',
        render: (_, partner) => (
          <Space>
            <ProtectedComponent requiredPermissions={[permissions.partner.update]}>
              <Tooltip title="Редактировать">
                <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => onUpdateClick(partner)} />
              </Tooltip>
            </ProtectedComponent>
            <ProtectedComponent requiredPermissions={[permissions.partner.merchants.view]}>
              <Tooltip title="Привязать к клиенту">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<LinkOutlined />}
                  onClick={() => onViewMerchantsClick(partner)}
                />
              </Tooltip>
            </ProtectedComponent>
          </Space>
        ),
      },
    ],
    [onUpdateClick],
  );
}
