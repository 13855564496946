import useSWRMutation from 'swr/mutation';
import { disableCardRequest } from 'modules/bankAccounts/api/disableCard.request';

export function useDisableCardRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'disable-card-request',
    (
      _,
      {
        arg,
      }: {
        arg: number;
      },
    ) => disableCardRequest(arg),
  );

  return { disableCard: trigger, isDisablingCard: isMutating };
}
