import { App, Button } from 'antd';
import React, { useEffect, useState } from 'react';

import {
  addOrderDispute,
  changePagination,
  fetchOrderDisputes,
  setBuffering,
} from 'modules/disputes/store/orderDisputes/actions';
import {
  IFetchOrderDisputesParams,
  IUpdateOrderDisputeStatusReducerActions,
  OrderDisputesActionTypes,
} from 'modules/disputes/store/orderDisputes/types';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';

import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useChannel } from 'hooks/useChannel.hook';
import { Filters } from 'components/Filters';
import { Pagination } from 'components/Pagination';
import { filtersSchema } from './filters';
import { OrderDisputesPageSmsModal } from './components/SmsModal';
import { OrderDisputesPageTable } from './components/Table';

export const OrderDisputesPage: React.FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<Partial<IFetchOrderDisputesParams>>();

  const [smsModalDispute, setSmsModalDispute] = useState<IOrderDispute>();

  const dispatch = useAppDispatch();

  const { message } = App.useApp();

  const {
    orderDisputes,
    pagination,
    pagination: { page, perPage },
    isLoading,
    isBuffering,
  } = useAppSelector((state) => state.orderDisputes);

  useEffect(() => {
    fetch(page, perPage);
  }, [page, perPage]);

  useEffect(() => {
    fetch(1);
  }, [memoFilters]);

  useChannel(
    'private-disputes',
    {
      '.created': (newOrderDispute: IOrderDispute) => {
        newOrderDispute.new = true;

        dispatch(addOrderDispute(newOrderDispute));
      },
      '.updated': (editedOrderDispute: IOrderDispute) => {
        dispatch<IUpdateOrderDisputeStatusReducerActions>({
          type: OrderDisputesActionTypes.UPDATE_STATUS,
          payload: editedOrderDispute,
        });
      },
    },
    [],
    page !== 1,
  );

  async function fetch(requestedPage = page, requestedPerPage = perPage) {
    try {
      await dispatch(
        fetchOrderDisputes({
          ...memoFilters,
          perPage: requestedPerPage,
          page: requestedPage,
        }),
      );
    } catch {
      message.error('Не удалось получить список диспутов');
    }
  }

  function handlePageChange(page: number, perPage: number) {
    dispatch<any>(changePagination(page, perPage));
  }

  function handleBufferingToggle() {
    dispatch<any>(setBuffering(!isBuffering));

    message.info(!isBuffering ? 'Поток диспутов остановлен' : 'Поток диспутов восстановлен');
  }

  return (
    <>
      <Filters
        headerTitle="Диспуты по заявкам"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        pauseButton={() => (
          <div>
            <Button
              icon={isBuffering ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
              onClick={handleBufferingToggle}
            />
          </div>
        )}
      />
      <OrderDisputesPageTable onShowSmsModal={setSmsModalDispute} data={orderDisputes} isLoading={isLoading} />
      <Pagination
        customStyle="my-2"
        pagination={pagination}
        isLoading={isLoading}
        onPaginationChange={handlePageChange}
      />
      <OrderDisputesPageSmsModal dispute={smsModalDispute} onCancel={() => setSmsModalDispute(undefined)} />
    </>
  );
};
