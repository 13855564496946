import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID',
    name: 'id',
    type: 'input-number',
  },
  {
    title: 'Наименование агента',
    name: 'name',
    type: 'input',
    width: 300,
  },
];
