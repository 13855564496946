import React from 'react';
import { Table } from 'antd';

import { Bank } from 'modules/banks/types';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useTableColumns } from './columns';

interface Props {
  onBankEditPress: (bank: Bank) => void;
  isSelectColumnShown: boolean;
}

export const BanksPageTable: React.FC<Props> = ({ onBankEditPress, isSelectColumnShown }) => {
  const { banks, isLoading } = useAppSelector((state) => state.banks);

  const bankMainSelected = useAppSelector((state) => state.banks.duplicates.mainBank);
  const banksMerge: Bank[] = useAppSelector((state) => state.banks.duplicates.mergeBanks) ?? [];

  let banksResult;

  if (bankMainSelected || banksMerge.length > 0) {
    banksResult = banks.map((bank) => {
      if (bankMainSelected) {
        bank.checkedMain = bankMainSelected.id === bank.id;
        bank.disabledMerge = bankMainSelected.id === bank.id;
      }

      if (banksMerge.length > 0) {
        banksMerge.forEach((bankMerge) => {
          if (bankMerge.id === bank.id) {
            bank.checkedMerge = true;
            bank.disabledMain = true;
          }
        });
      }

      return bank;
    });
  } else {
    banksResult = banks;
  }

  const columns = useTableColumns({ onBankEditPress, isSelectColumnShown });

  return (
    <Table
      columns={columns}
      dataSource={banksResult}
      loading={isLoading}
      pagination={false}
      rowKey={(record) => record.id}
    />
  );
};
