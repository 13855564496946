import React from 'react';
import { Button, Form, type FormInstance, Spin } from 'antd';
import { FormFieldSchema } from './types/fieldsSchema.interface';
import { UnifiedFormItem } from './components/Item';

interface Props<T> {
  fields: FormFieldSchema<T>[];
  form: FormInstance<T>;
  onSubmitClick: () => void;
  submitButtonTitle?: string;
  isSubmitButtonAtBottom?: boolean;
  className?: string;
  isLoading?: boolean;
}

export const UnifiedForm = <T,>({
  fields,
  submitButtonTitle,
  className,
  onSubmitClick,
  isSubmitButtonAtBottom,
  form,
  isLoading,
}: Props<T>) => {
  return (
    <Form className={className} form={form} onFinish={onSubmitClick}>
      <Spin spinning={isLoading}>
        {fields.map((schema) => (
          <UnifiedFormItem key={schema.name as string} schema={schema} />
        ))}
      </Spin>
      <Button
        className={isSubmitButtonAtBottom ? 'mt-auto' : 'mt-4'}
        htmlType="submit"
        type="primary"
        loading={isLoading}
        disabled={isLoading}
      >
        {submitButtonTitle ?? 'Сохранить'}
      </Button>
    </Form>
  );
};
