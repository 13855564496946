import { Order } from 'modules/orders/types/order.interface';
import { ReducerAction } from 'types/reducerAction.interface';
import { FetchOrdersPayload } from './interfaces/fetchOrdersPayload.interface';

export interface IChangePaginationPayload {
  page: number;
  perPage: number;
}

export type GetOrdersReducerAction = ReducerAction<FetchOrdersPayload, OrdersActionsTypes.GET_ORDERS>;

export type AddOrderReducerAction = ReducerAction<Order, OrdersActionsTypes.ADD_ORDER>;

export type EditOrderReducerAction = ReducerAction<Order, OrdersActionsTypes.EDIT_ORDER>;

export type RefundOrderReducerAction = ReducerAction<Order, OrdersActionsTypes.REFUND_ORDER>;

export type SetIsLoadingOrdersReducerAction = ReducerAction<boolean, OrdersActionsTypes.SET_IS_LOADING>;

export type ChangePageOrdersReducerAction = ReducerAction<IChangePaginationPayload, OrdersActionsTypes.CHANGE_PAGE>;

export enum OrdersActionsTypes {
  GET_ORDERS = 'GET_ORDERS',
  ADD_ORDER = 'ADD_ORDER',
  EDIT_ORDER = 'EDIT_ORDER',
  REFUND_ORDER = 'REFUND_ORDER',
  SET_IS_LOADING = 'SET_IS_LOADING',
  CHANGE_PAGE = 'CHANGE_PAGE',
}

export type OrdersReducerActions =
  | GetOrdersReducerAction
  | AddOrderReducerAction
  | RefundOrderReducerAction
  | EditOrderReducerAction
  | SetIsLoadingOrdersReducerAction
  | ChangePageOrdersReducerAction;
