import { Button, App } from 'antd';
import React, { useEffect } from 'react';

import { Filters } from 'components/Filters';
import { OwnersSMSPageTable } from 'modules/owners/pages/sms/components/Table';

import { IExportOwnersSmsParams } from 'modules/owners/pages/sms/types';
import { changePagination, fetchSms } from 'modules/owners/store/ownersSms/actions';
import { IFetchSmsOwnersSmsParams } from 'modules/owners/store/ownersSms/types';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';

import { downloadAndSaveFrom } from 'utils/export';

import { DownloadOutlined } from '@ant-design/icons';
import { filtersSchema } from 'modules/owners/pages/sms/filters';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { IOrderDispute } from 'modules/disputes/types/IOrderDispute';

interface Props {
  dispute?: IOrderDispute;
  embedded?: boolean;
}

export const OwnersSMSPage: React.FC<Props> = ({ dispute, embedded }) => {
  const { memoFilters, setMemoFilters } = useMemoFilters<IFetchSmsOwnersSmsParams>(
    !embedded,
    embedded ? 'embedded-owners-sms' : undefined,
  );

  const { message } = App.useApp();

  const {
    pagination: { page, perPage },
  } = useAppSelector((state) => state.ownersSms);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetch(page);
  }, [page, perPage]);

  useEffect(() => {
    fetch(1);
  }, [memoFilters]);

  function fetch(requestedPage = page) {
    try {
      dispatch(
        fetchSms({
          ownerId: dispute?.ownerId,
          ...memoFilters,
          page: requestedPage,
          perPage: perPage,
        }),
      );
    } catch {
      message.error('Не удалось получить список СМС!');
    }
  }

  function handlePaginationReset() {
    dispatch(changePagination(1, 20));
  }

  const exportInFile = async (filters: IFetchSmsOwnersSmsParams) => {
    try {
      const { createdAtFrom, createdAtTo, bankId, ownerId, amountTo, amountFrom, hasLinkedTransaction, type } = filters;

      if (!createdAtFrom || !createdAtTo) {
        message.error('Фильтры дата создания от и до обязательны для экспорта');

        return;
      }

      const params: IExportOwnersSmsParams = {
        from: createdAtFrom,
        to: createdAtTo,
        bankId,
        ownerId,
        amountFrom,
        amountTo,
        hasLinkedTransaction,
        type,
      };

      await downloadAndSaveFrom<IExportOwnersSmsParams>('/owners/export/sms', params);
    } catch {
      message.error('Не удалось скачать СМС');
    }
  };

  return (
    <>
      <Filters
        hideHeader={embedded}
        headerTitle="СМС владельцев"
        filters={dispute?.ownerId ? filtersSchema.slice(1) : filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        onClear={handlePaginationReset}
        customButtons={(getFilters) =>
          embedded ? (
            <></>
          ) : (
            <>
              <Button
                className="bg-green-600 border-green-600 hover:bg-green-500"
                type="default"
                icon={<DownloadOutlined />}
                onClick={() => exportInFile(getFilters())}
                style={{ color: 'white', border: 'none' }}
              >
                Скачать в .xlsx
              </Button>
            </>
          )
        }
      />
      <OwnersSMSPageTable />
    </>
  );
};
