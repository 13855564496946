import useSWRMutation from 'swr/mutation';
import { setConfirmWithRequest, SetConfirmWithRequestParams } from 'modules/banks/api';

export function useSetConfirmWithRequest() {
  const { isMutating, trigger } = useSWRMutation(
    'set-confirm-with-request',
    (_, { arg }: { arg: SetConfirmWithRequestParams }) => setConfirmWithRequest(arg),
    { throwOnError: true },
  );

  return { isSettingConfirmWith: isMutating, setConfirmWith: trigger };
}
