import instance from 'utils/axios';
import { MerchantCommission } from 'modules/merchants/types';

export async function fetchMerchantCommissionsRequest(merchantId: number): Promise<MerchantCommission[]> {
  const {
    data: { data },
  } = await instance.get('/merchants/commissions/list', { params: { merchantId } });

  return data;
}
