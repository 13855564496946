import useSWRMutation from 'swr/mutation';
import { enableSbpOnBankRequest, disableSbpOnBankRequest } from 'modules/banks/api';

interface UseToggleSbpBankRequestParams {
  id: number;
  enabled: boolean;
}

export function useToggleSbpBankRequest() {
  const { isMutating, trigger } = useSWRMutation(
    'toggle-sbp-bank-request',
    async (_, { arg }: { arg: UseToggleSbpBankRequestParams }) => {
      if (arg.enabled) {
        await enableSbpOnBankRequest(arg.id);
      } else {
        await disableSbpOnBankRequest(arg.id);
      }
    },
    { throwOnError: true },
  );

  return { isTogglingSbpOnBank: isMutating, toggleSbpOnBank: trigger };
}
