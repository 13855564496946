import React, { useEffect, useMemo, useState } from 'react';
import { App, Button, Drawer, Form, Input, Space, Spin, Switch, Table } from 'antd';
import { useNotDeletedMerchantsFromServer } from 'hooks/useDataFromServer';
import instance from 'utils/axios';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { Partner } from 'modules/partners/types/partner.interface';
import { PartnersPageMerchantsDrawerFormValues } from './types/formValues.type';

interface Props {
  partner?: Partner;
  onClose: () => void;
}

export const PartnersPageMerchantsDrawer: React.FC<Props> = ({ partner, onClose }) => {
  const { message } = App.useApp();

  const [linkedMerchantsIds, setLinkedMerchantsIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = Form.useForm<PartnersPageMerchantsDrawerFormValues>();

  const [merchants] = useNotDeletedMerchantsFromServer();

  useEffect(() => {
    if (!partner) {
      setLinkedMerchantsIds([]);

      return;
    }

    fetchLinkedMerchants();
  }, [partner]);

  const data = useMemo(() => {
    if (!linkedMerchantsIds || !merchants) {
      return;
    }

    return merchants.map((merchant) => ({
      id: merchant.id,
      name: merchant.name,
      enabled: linkedMerchantsIds.some((id) => id === merchant.id),
    }));
  }, [linkedMerchantsIds, merchants]);

  useEffect(() => {
    if (!data?.length || !partner) {
      return;
    }

    form.setFieldsValue(data.map(({ id, enabled }) => ({ id, enabled })));
  }, [data, partner]);

  async function fetchLinkedMerchants(): Promise<void> {
    try {
      setIsLoading(true);

      const { data } = await instance.get(`partners/${partner!.id}/merchants`);

      setLinkedMerchantsIds(data.data);
    } catch {
      message.error('Не удалось загрузить владельцев');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmit(): Promise<void> {
    setIsLoading(true);

    const values = form.getFieldsValue();

    try {
      await instance.post('partners/assign-merchants', {
        id: partner!.id,
        merchantsIds: Object.values(values)
          .filter((value) => value.enabled)
          .map((value) => value.id),
      });
    } catch {
      message.error('Не удалось привязать мерчантов к провайдеру');
    } finally {
      setIsLoading(false);
      onClose();
    }
  }

  function handleClose() {
    form.resetFields();
    onClose();
  }

  return (
    <Drawer title="Привязанные клиенты" open={!!partner} onClose={handleClose}>
      <Spin spinning={isLoading} tip="Загрузка...">
        <Space direction="vertical">
          <Form form={form}>
            <Table
              columns={[
                {
                  title: 'name',
                  key: 'name',
                  dataIndex: 'name',
                  render: (_, partner, index) => (
                    <>
                      <Form.Item hidden name={[index, 'id']}>
                        <Input type="hidden" />
                      </Form.Item>
                      {partner.name}
                    </>
                  ),
                },
                {
                  title: 'Подключен',
                  key: 'enabled',
                  dataIndex: 'enabled',
                  render: (_, __, index) => (
                    <Form.Item name={[index, 'enabled']} noStyle>
                      <Switch />
                    </Form.Item>
                  ),
                },
              ]}
              dataSource={data}
              pagination={false}
              rowKey={(record) => record.name}
            />
          </Form>
          <ProtectedComponent requiredPermissions={[permissions.partner.merchants.update]}>
            <Button type="primary" onClick={handleSubmit}>
              Сохранить
            </Button>
          </ProtectedComponent>
        </Space>
      </Spin>
    </Drawer>
  );
};
