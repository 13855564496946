import useSWR from 'swr';
import {
  fetchBankAccountsRequest,
  FetchBankAccountsRequestParams,
} from 'modules/bankAccounts/api/fetchBankAccounts.request';

export function useFetchBankAccountsRequest(params: FetchBankAccountsRequestParams) {
  const { data, isLoading, mutate } = useSWR([params, 'fetch-bank-accounts'], ([params]) =>
    fetchBankAccountsRequest(params),
  );

  return { bankAccountsData: data, isFetchingBankAccounts: isLoading, fetchBankAccounts: mutate };
}
