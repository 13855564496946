import React from 'react';
import { Typography } from 'antd';

import { GoipsPageTable } from 'modules/goips/pages/list/components/Table';

export const GoipsPage: React.FC = () => {
  return (
    <>
      <Typography.Title>Шлюзы</Typography.Title>
      <GoipsPageTable />
    </>
  );
};
