import React from 'react';
import { Divider, Switch, Tooltip } from 'antd';
import Link from 'antd/lib/typography/Link';

import { GoipPortOwner } from 'modules/goips/store/goips/types';
import { useTheme } from 'contexts';

interface Props {
  owner: GoipPortOwner;
  switchOwnerStatus: () => void;
}

export const GoipLinkedOwner: React.FC<Props> = ({ owner, switchOwnerStatus }) => {
  const {
    currentTheme: { token },
  } = useTheme();

  const { colorPrimary } = token ?? {};

  const { id, fio, isActive } = owner;

  return (
    <>
      <Link
        className="text-right w-64 truncate"
        target={'_blank'}
        href={`/owners?ownerId=${id}`}
        style={{ color: colorPrimary }}
      >
        {id} - {fio}
      </Link>
      <Divider type="vertical" />
      <Tooltip title="Отключить/Включить владельца из-за недоступности шлюза">
        <Switch checked={isActive} onClick={switchOwnerStatus} />
      </Tooltip>
    </>
  );
};
