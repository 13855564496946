import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';
import { Button, Space, Switch, Typography } from 'antd';
import { DownloadOutlined, TableOutlined, UserAddOutlined } from '@ant-design/icons';
import React from 'react';
import { IFetchOwnersParams } from 'modules/owners/store/owners/types';
import { OwnersPageGoipCascader } from 'modules/owners/pages/list/components/GoipCascader/OwnersPageGoipCascader';
import { FiltersValue } from 'components/Filters/types/filtersValue.type';
import { Filters } from 'components/Filters';
import { filtersSchema } from '../../filters';

interface Props {
  currentFilters: IFetchOwnersParams;
  submittedFilters: IFetchOwnersParams;
  onSubmit: (value: FiltersValue) => void;
  onClear: () => void;
  onAddPress: () => void;
  isLoading: boolean;
  isTableView: boolean;
  onViewToggleChanged: (checked: boolean) => void;
  onChange: (values: IFetchOwnersParams) => void;
  onExport: () => void;
}

export const OwnersPageFilter: React.FC<Props> = ({
  currentFilters,
  submittedFilters,
  onSubmit,
  onClear,
  onAddPress,
  isLoading,
  isTableView,
  onViewToggleChanged,
  onChange,
  onExport,
}) => {
  return (
    <Filters
      headerTitle="Владельцы"
      filters={filtersSchema}
      value={submittedFilters}
      onSubmit={onSubmit}
      onClear={onClear}
      extraHeaderButtons={() => (
        <>
          <ProtectedComponent requiredPermissions={[permissions.owner.create]}>
            <Button onClick={onAddPress} type="primary" shape="round" icon={<UserAddOutlined />}>
              Добавить
            </Button>
          </ProtectedComponent>
        </>
      )}
      extraHeaderViewButtons={() => (
        <Space className="right-0 pr-3">
          <TableOutlined className="text-blue-500" />
          <Typography.Text>Табличный вид</Typography.Text>
          <Switch disabled={isLoading} checked={isTableView} onChange={onViewToggleChanged} />
        </Space>
      )}
      customFilters={() => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '20px',
            fontSize: '12px',
            lineHeight: 2.1,
            marginBottom: '5px',
          }}
        >
          <span>Шлюзы</span>
          <OwnersPageGoipCascader params={currentFilters} onChange={onChange} />
        </div>
      )}
      customButtons={() => (
        <Button
          className="bg-green-600 border-green-600 hover:bg-green-500"
          type="default"
          icon={<DownloadOutlined />}
          onClick={onExport}
          style={{ color: 'white', border: 'none' }}
        >
          Скачать в .xlsx
        </Button>
      )}
    />
  );
};
