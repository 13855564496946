import useSWRMutation from 'swr/mutation';
import { createUserRequest, CreateUserRequestParams } from 'modules/users/api/createUser.request';

export function useCreateUserRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'create-user-request',
    async (_, { arg }: { arg: CreateUserRequestParams }) => {
      return await createUserRequest(arg);
    },
    { throwOnError: true },
  );

  return { createUser: trigger, isCreatingUser: isMutating };
}
