import React, { useMemo } from 'react';
import { Button, Divider } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DownloadOutlined } from '@ant-design/icons';

import { Report } from 'modules/reports/types/report.interface';
import { DeleteReportButton } from 'modules/reports/components/DeleteReportButton';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { utcDateTimeToMoscow } from 'utils';

dayjs.extend(utc);
dayjs.extend(tz);

export function useReportsTableColumns(onDeleteSuccess: () => void) {
  return useMemo<ColumnsType<Report>>(() => {
    return [
      {
        dataIndex: 'type',
        title: 'Тип',
      },
      {
        dataIndex: 'createdAt',
        title: 'Дата формирования (МСК)',
        render: (createdAt: string) => utcDateTimeToMoscow(createdAt),
      },
      {
        dataIndex: 'filename',
        title: 'Имя файла',
      },
      {
        title: 'Действия',
        render: (report: Report) => (
          <>
            <Button href={report.url} type="primary" shape="round" icon={<DownloadOutlined />} size="middle" />
            <Divider type="vertical" />
            <DeleteReportButton id={report.id} onSuccess={onDeleteSuccess} />
          </>
        ),
      },
    ];
  }, [onDeleteSuccess]);
}
