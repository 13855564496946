import { ColumnType } from 'antd/lib/table';
import { Image } from 'antd';
import React from 'react';
import { OwnerTransaction } from 'modules/owners/types/OwnerTransaction';
import { Tooltip } from 'components/Tooltip';
import { utcDateTimeToLocal } from 'utils';

export function makeColumns(): ColumnType<OwnerTransaction>[] {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Владелец',
      key: 'owner',
      render: (_, { owner }) => `№${owner.ownerId} - ${owner.fio}`,
    },
    {
      title: 'Сумма операции',
      dataIndex: 'sum',
      key: 'sum',
      render: (_, { sum }) => sum?.toLocaleString('ru-RU') ?? '- - -',
    },
    {
      title: 'Банк',
      key: 'provider',
      render: (_, { provider: { name, image } }) => (
        <Tooltip title={name}>
          <Image src={image} width={80} alt={name} preview={false} />
        </Tooltip>
      ),
    },
    {
      title: 'Создано',
      key: 'createdAt',
      render: (_, { createdAt }) => {
        if (createdAt) {
          return utcDateTimeToLocal(createdAt);
        } else {
          return 'Нет данных';
        }
      },
    },
    {
      title: 'Источник',
      dataIndex: ['source', 'name'],
      key: 'sourceName',
    },
    {
      title: 'Баланс после операции',
      key: 'newBalance',
      render: (_, { newBalance }) => newBalance?.toLocaleString('ru-RU') ?? '',
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
    },
  ];
}
