import api from 'utils/axios';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';
import { AppDispatch } from 'app/store';
import { FetchMerchantsBalancesParams } from './interfaces/fetchMerchantsBalancesParams.interface';
import { MerchantsBalancesActionsTypes } from './types';

export function fetchMerchantsBalances(params: FetchMerchantsBalancesParams & RequestParamsWithPagination) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading());

      const {
        data: { data: merchantsBalances, meta: pagination },
      } = await api.get('/merchants/balances/list', {
        params: sanitizeRequestParams(params),
      });

      dispatch({
        type: MerchantsBalancesActionsTypes.FETCH_MERCHANTS_BALANCES,
        payload: { merchantsBalances, pagination },
      });
    } catch {
      dispatch(setLoading(false));
    }
  };
}

export function setLoading(value: boolean = true) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: MerchantsBalancesActionsTypes.SET_LOADING,
      payload: value,
    });
  };
}

export function changePagination(page: number, perPage: number) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: MerchantsBalancesActionsTypes.CHANGE_PAGE,
      payload: { page, perPage },
    });
  };
}
