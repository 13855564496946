import { LoginOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Button, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import { logout } from 'modules/auth/store/auth/actions';

import LogoDarkIconSvg from 'assets/svg/Logo-dark.svg';
import LogoLightIconSvg from 'assets/svg/Logo-light.svg';

import { UpdateMessage } from 'components/UpdateMessage';

import { useTheme } from 'contexts';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

import { LayoutSidebar } from 'components/Layout/components/Sidebar';
import { ThemeSwitcher } from 'components/ThemeSwitcher/ThemeSwitcher';
import { LayoutHeader } from 'components/Layout/components/Header';
import { getLayoutStyles } from './styles';
import { useUserData } from './hooks/useUserData.hook';

const { Sider, Content } = AntdLayout;

export const Layout: React.FC = () => {
  const [isScreenNarrow, setIsScreenNarrow] = useState(window.innerWidth < 1350);
  const [isSiderShown, setIsSiderShown] = useState(false);

  const dispatch = useAppDispatch();

  const { userName, userRole } = useUserData();

  const {
    choose,
    currentTheme: { token },
  } = useTheme();

  const { colorBgBase, colorBgBody, colorBorderInput } = token ?? {};

  const colorScrollbar = choose('#292e31 #12171a', 'auto');
  const styles = getLayoutStyles(colorScrollbar);

  useEffect(() => {
    document.body.classList.toggle('no-scroll-sidebar-hidden', isSiderShown);

    return () => {
      document.body.classList.remove('no-scroll-sidebar-hidden');
    };
  }, [isSiderShown]);

  function handleResize() {
    setIsSiderShown(false);
    setIsScreenNarrow(window.innerWidth < 1350);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function onSiderToggle(isSiderShown: boolean): void {
    setIsSiderShown(!isSiderShown);
  }

  function handleCloseBackdrop(): void {
    setIsSiderShown(false);
  }

  return (
    <div style={isSiderShown ? { overflowY: 'hidden' } : styles.scrollBar}>
      <AntdLayout style={isScreenNarrow ? styles.layout.narrow : styles.layout.normal}>
        <Sider
          style={{
            ...(isScreenNarrow
              ? { ...styles.sider.narrow, left: isSiderShown ? '0px' : '-200px' }
              : styles.sider.normal),
            background: colorBgBase,
          }}
          className="sidebar"
        >
          <div className={isScreenNarrow ? 'flex flex-col justify-between' : 'flex flex-col justify-between h-screen'}>
            <div>
              {isScreenNarrow || (
                <Link to="/">
                  <img src={choose(LogoLightIconSvg, LogoDarkIconSvg)} className="w-36 m-auto pt-6 pb-6" alt="Logo" />
                </Link>
              )}
              <LayoutSidebar />
            </div>
            <div className="flex flex-col pb-6 column pl-6 pr-6 pt-6 border-t border-grey-500 border-solid border-1">
              <div className="flex items-start">
                <Button
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    padding: '4px 6px',
                    display: 'flex',
                    alignItems: 'baseline',
                    height: 'auto',
                  }}
                  onClick={() => dispatch<any>(logout())}
                >
                  <div style={{ marginTop: '2px' }}>
                    <LoginOutlined style={{ marginRight: '8px' }} />
                  </div>
                  <div style={{ maxWidth: '120px', textAlign: 'left', textWrap: 'wrap' }}>
                    <Tooltip title={userName && userName.length > 15 ? userName : ''}>
                      <span
                        style={{
                          display: 'inline-block',
                          maxWidth: '120px',
                          textAlign: 'left',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          verticalAlign: 'bottom',
                        }}
                      >
                        {userName}:&nbsp;
                      </span>
                    </Tooltip>

                    <span style={{ color: choose(colorBorderInput, 'rgb(160, 160, 160)') }}>{userRole}</span>
                  </div>
                </Button>
              </div>
              <div className="flex pt-5 pl-1">
                <div>{isScreenNarrow || <ThemeSwitcher />}</div>
              </div>
            </div>
          </div>
        </Sider>
        <AntdLayout style={isScreenNarrow ? styles.main.narrow : styles.main.normal}>
          {isScreenNarrow && <LayoutHeader isSiderShown={isSiderShown} onSiderToggle={onSiderToggle} />}
          {isScreenNarrow && isSiderShown && (
            <div
              onClick={handleCloseBackdrop}
              className="fixed top-0 left-0 w-full h-full"
              style={choose(
                { background: 'rgba(255, 255, 255, 0.4)', zIndex: 9 },
                { background: 'rgba(0, 0, 0, 0.4)', zIndex: 9 },
              )}
            />
          )}
          <Content
            className="z-6"
            style={{
              ...styles.content,
              background: colorBgBody,
              ...(isScreenNarrow && { marginTop: '50px' }),
            }}
          >
            <UpdateMessage />
            <Outlet />
          </Content>
        </AntdLayout>
      </AntdLayout>
    </div>
  );
};
