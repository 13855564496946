import { type FormInstance } from 'antd';
import { Dayjs } from 'dayjs';
import { Rule } from 'antd/lib/form';

export const fromFieldValidatorRules = (form: FormInstance) => [
  {
    message: 'Укажите начало периода!',
    required: true,
  },
  {
    validator: async (_: unknown, from: Dayjs) => {
      const to = (form.getFieldValue('to') as Dayjs)?.startOf('day');

      if (!from || !to) return;

      from = from.startOf('day');

      if (from.isAfter(to)) {
        throw new Error('Начало периода не может быть больше, чем окончание периода!');
      }
      if (from.isSame(to)) {
        throw new Error('Начало и окончание периода не могут совпадать!');
      }
    },
  },
];

export const toFieldValidatorRules = (form: FormInstance) => [
  {
    message: 'Укажите окончание периода!',
    required: true,
  },
  {
    validator: async (_: unknown, to: Dayjs) => {
      const from = (form.getFieldValue('from') as Dayjs)?.startOf('day');

      if (!to || !from) return;

      to = to.startOf('day');

      if (to.isBefore(from)) {
        throw new Error('Окончание периода не может быть меньше, чем начало периода');
      }
      if (to.isSame(from)) {
        throw new Error('Начало и окончание периода не могут совпадать!');
      }
    },
  },
];

export const ownerIdFieldValidatorRules: Rule[] = [
  {
    min: 0,
    type: 'number',
    message: 'Идентификатор не может быть отрицательным числом!',
  },
];
