import React, { useEffect, useMemo } from 'react';
import { Alert, Form, Input, Modal, Select } from 'antd';

import { InputNumber } from 'components/Input';

import { permissions } from 'policies/permissions';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { Order } from 'modules/orders/types/order.interface';
import { OrderStatusEnum } from 'modules/orders/enums/orderStatus.enum';
import {
  incomingStatusOptions,
  notEnteredAvailableStatusOptions,
  outcomingStatusOptions,
  undoneAvailableStatusOptions,
} from 'modules/orders/pages/list/components/EditModal/constants';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';
import { finalStatusesAllowedToEdit } from 'modules/orders/pages/list/components/Table/utils';
import { OrdersPageEditModalInOrder } from './components/InOrder';
import { OrdersPageEditModalOutOrder } from './components/OutOrder';

interface Props {
  onClose: () => void;
  order?: Order;
}

export interface CommonFields {
  id: number;
  status: OrderStatusEnum;
  realSum: number;
  comment: string;
  amount: number;
}

export const OrdersPageEditModal: React.FC<Props> = ({ onClose, order }) => {
  const [form] = Form.useForm<CommonFields>();

  const { userCan } = useUserPermissions();

  useEffect(() => {
    if (!order) {
      return;
    }

    form.setFieldsValue({ ...order, status: order.status.id });
  }, [order]);

  const statusOptions = useMemo(() => {
    if (OrderStatusEnum.NOT_ENTERED === order?.status.id) {
      return notEnteredAvailableStatusOptions;
    }

    if (OrderStatusEnum.NOT_EXECUTED === order?.status.id) {
      return undoneAvailableStatusOptions;
    }

    return order?.type === OrderTypes.INCOMING ? incomingStatusOptions : outcomingStatusOptions;
  }, [order]);

  if (!order) return null;

  const isFinalStatusAllowedToEdit = finalStatusesAllowedToEdit.includes(order.status.id);
  const excludedStatuses = [OrderStatusEnum.NOT_ENTERED, OrderStatusEnum.NOT_EXECUTED];
  const isAllowedToEditFinalOrder =
    isFinalStatusAllowedToEdit &&
    !excludedStatuses.includes(order.status.id) &&
    userCan(permissions.order.final.update);

  let content;

  const ownersWithoutBankAccount = order.owners.filter((owner) => !owner.bankAccountId || !owner.bankId);

  if (ownersWithoutBankAccount.length === 0) {
    content = (
      <>
        <Form form={form}>
          <Form.Item hidden name="id">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item hidden name="amount" />
          <Form.Item name="status" label="Статус транзакции">
            <Select disabled={isAllowedToEditFinalOrder} options={statusOptions} />
          </Form.Item>
          <Form.Item name="realSum" label="Реальная сумма транзакции">
            <InputNumber
              disabled={isAllowedToEditFinalOrder || order?.status.id === OrderStatusEnum.NOT_EXECUTED}
              isAmountInput
            />
          </Form.Item>
          <Form.Item hidden={isFinalStatusAllowedToEdit} name="comment" label="Комментарий">
            <Input />
          </Form.Item>
        </Form>
        {order.type === OrderTypes.OUTCOMING ? (
          <OrdersPageEditModalOutOrder
            order={order}
            onClose={onClose}
            onSubmit={() => form.getFieldsValue()}
            isAllowedToEditCommissions={isFinalStatusAllowedToEdit}
          />
        ) : (
          <OrdersPageEditModalInOrder order={order} onSubmit={() => form.getFieldsValue()} onClose={onClose} />
        )}
      </>
    );
  } else {
    const ownersIds = ownersWithoutBankAccount.map((owner) => '№' + owner.ownerId).join(', ');

    content = (
      <Alert
        message="Редактирование заявки запрещено"
        description={`Владельцы ${ownersIds} не имеют банковского счета`}
        type="error"
      />
    );
  }

  function handleClose() {
    form.resetFields();
    onClose();
  }

  return (
    <Modal
      title={'Редактирование - ' + order.customerTransactionId}
      open={!!order}
      onCancel={handleClose}
      footer={null}
      width={750}
    >
      {content}
    </Modal>
  );
};
