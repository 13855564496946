import React, { useMemo } from 'react';

import { Button, Form, Select, Space } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';

import type { ValueType } from '@rc-component/mini-decimal';

import { CurrencyNameEnum } from 'enums/CurrencyEnum';

import { EditCommissionStepColumn, EditCommissionStepModel, MerchantCommissionStep } from 'modules/merchants/types';
import { ICurrency } from 'types/currency.interface';
import { InputNumber } from 'components/Input';
import { TableMerchantModalCommission } from 'modules/merchants/components/EditMerchantCommissionsDrawer/types';

export const useMerchantCommissionsModalColumns = (
  currencies: ICurrency[],
  tableData: TableMerchantModalCommission[],
  onCurrencySelect: (currency: string, index: number) => void,
  onCommissionChange: (
    commission: ValueType | null,
    index: number,
    commissionName: EditCommissionStepColumn,
    commissionType: EditCommissionStepModel,
  ) => void,
  onRemoveRowByIndex: (index: number) => void,
): ColumnType<MerchantCommissionStep>[] => {
  const options = useMemo(() => {
    const usedCurrencies = tableData.map((item) => item.currencyCode);

    return currencies.map((currency) => ({
      label: currency.code,
      value: currency.code,
      disabled: usedCurrencies.includes(currency.code!),
    }));
  }, [tableData, currencies]);

  return useMemo<ColumnType<MerchantCommissionStep>[]>(
    () => [
      {
        title: 'Валюта',
        dataIndex: 'currencyCode',
        key: 'currencyCode',
        render: (_, { currencyCode }, index) => {
          return (
            <Form.Item className="w-20" name={[index, 'currencyCode']}>
              <Select
                placeholder="Валюта"
                onChange={(value) => onCurrencySelect(value, index)}
                options={options}
                defaultValue={currencyCode}
              />
            </Form.Item>
          );
        },
      },
      {
        title: 'Входящие',
        dataIndex: 'incomingValue',
        key: 'incomingValue',
        render: (_, { currencyCode }, index) => {
          return (
            <Space direction="vertical">
              <InputNumber
                onChange={(value) => {
                  onCommissionChange(value, index, 'incomingValue', 'p2p');
                }}
                size="middle"
                className="w-60"
                name={[index, 'incomingValue', 'p2p']}
                addonBefore="P2P"
                addonAfter="%"
              />
              <InputNumber
                onChange={(value) => {
                  onCommissionChange(value, index, 'incomingValue', 'c2c');
                }}
                size="middle"
                name={[index, 'incomingValue', 'c2c']}
                className="w-60"
                addonBefore="C2C"
                disabled={currencyCode !== CurrencyNameEnum.RUB}
                addonAfter="%"
              />
            </Space>
          );
        },
      },
      {
        title: 'Исходящие',
        dataIndex: 'outcomingValue',
        key: 'outcomingValue',
        render: (_, { currencyCode }, index) => {
          return (
            <Space direction="vertical">
              <InputNumber
                onChange={(value) => {
                  onCommissionChange(value, index, 'outcomingValue', 'p2p');
                }}
                size="middle"
                className="w-60"
                name={[index, 'outcomingValue', 'p2p']}
                addonBefore="P2P"
                addonAfter="%"
              />
              <InputNumber
                onChange={(value) => {
                  onCommissionChange(value, index, 'outcomingValue', 'c2c');
                }}
                size="middle"
                name={[index, 'outcomingValue', 'c2c']}
                className="w-60"
                addonBefore="C2C"
                disabled={currencyCode !== CurrencyNameEnum.RUB}
                addonAfter="%"
              />
            </Space>
          );
        },
      },
      {
        render: (_, __, index) => {
          return <Button onClick={() => onRemoveRowByIndex(index)} danger type="text" icon={<DeleteOutlined />} />;
        },
      },
    ],
    [options],
  );
};
