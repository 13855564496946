import { App, Select } from 'antd';
import { BankConfirmWithEnum } from 'modules/banks/enums';
import { useSetConfirmWithRequest } from 'modules/banks/hooks';
import { Bank } from 'modules/banks/types';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';
import React, { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';

interface Props {
  bank: Bank;
}

export const ConfirmWithSelect: React.FC<Props> = ({ bank }) => {
  const [value, setValue] = useState<BankConfirmWithEnum>(BankConfirmWithEnum.SMS);

  const { setConfirmWith, isSettingConfirmWith } = useSetConfirmWithRequest();

  const { message } = App.useApp();

  const { userCan } = useUserPermissions();

  useEffect(() => {
    setValue(bank.confirmWith);
  }, [bank]);

  async function handleBankPaymentNotificationUpdate(newValue: BankConfirmWithEnum) {
    try {
      await setConfirmWith({ id: bank.id, confirmWith: newValue });

      message.success({
        content: 'Метод платёжного уведомления успешно изменён!',
        duration: 1.5,
      });

      setValue(newValue);
    } catch (err) {
      captureException(err);

      message.error('Ошибка изменения метода платёжного уведомления!');
    }
  }

  return (
    <Select
      options={[
        {
          value: BankConfirmWithEnum.PUSH,
          label: 'Push',
        },
        {
          value: BankConfirmWithEnum.SMS,
          label: 'SMS',
        },
      ]}
      value={value}
      onChange={handleBankPaymentNotificationUpdate}
      style={{ minWidth: 150 }}
      loading={isSettingConfirmWith}
      disabled={!userCan(permissions.bank.confirmWIth.update)}
    />
  );
};
