import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { useCurrenciesFromServer } from 'hooks/useDataFromServer';
import { BankConfirmWithEnum } from 'modules/banks/enums';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID банка',
    name: 'id',
    type: 'input',
    rules: [
      {
        pattern: /^[0-9]+$/,
        message: 'Можно вводить только цифры',
      },
    ],
  },
  {
    title: 'Название банка',
    name: 'name',
    type: 'input',
    rules: [
      {
        pattern: /^[a-zA-Zа-яА-Я0-9]+$/,
        message: 'Можно вводить только русские и английские буквы, а также цифры',
      },
    ],
    allowClear: true,
    width: 300,
  },
  {
    title: 'Тип банка',
    name: 'internal',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Внутренний',
        value: 1,
      },
      {
        label: 'Внешний',
        value: 0,
      },
    ],
  },
  {
    title: 'Статус банка',
    name: 'enabled',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Включен',
        value: 1,
      },
      {
        label: 'Отключен',
        value: 0,
      },
    ],
  },
  {
    title: 'Валюта',
    name: 'currencyCode',
    type: 'lazy-select',
    allowClear: true,
    useDataHook: useCurrenciesFromServer,
    cacheKey: 'currency-select',
    dataTransformer: (item) => {
      return {
        label: `${item.code} (${item.name})`,
        value: item.code,
      };
    },
  },
  {
    title: 'Работа с СБП',
    name: 'sbpEnabled',
    type: 'select',
    allowClear: true,
    options: [
      {
        label: 'Включен',
        value: 1,
      },
      {
        label: 'Отключен',
        value: 0,
      },
    ],
  },
  {
    title: 'Тип подтверждения',
    name: 'confirmWith',
    type: 'select',
    allowClear: true,
    options: [
      {
        value: BankConfirmWithEnum.PUSH,
        label: 'Push',
      },
      {
        value: BankConfirmWithEnum.SMS,
        label: 'SMS',
      },
    ],
  },
];
