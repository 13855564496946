import React, { useEffect, type PropsWithChildren, useMemo } from 'react';

import { useTheme } from 'contexts';
import { ConfigProvider, theme } from 'antd';

export const ThemeConfigProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isDarkMode, currentTheme } = useTheme();

  useEffect(() => {
    if (currentTheme.token) {
      document.body.style.color = currentTheme.token.colorTextBase!;
      document.body.style.backgroundColor = currentTheme.token.colorBgBody;
    }

    const htmlElement = document.querySelector('html');

    document.body.classList.toggle('dark-theme', isDarkMode);
    document.body.classList.toggle('light-theme', !isDarkMode);
    htmlElement?.classList.toggle('dark-theme', isDarkMode);
    htmlElement?.classList.toggle('light-theme', !isDarkMode);
    document.getElementById('root')?.classList.toggle('dark-mode', isDarkMode);
    document.getElementById('root')?.classList.toggle('light-mode', !isDarkMode);
  }, [currentTheme, isDarkMode]);

  const { defaultAlgorithm, darkAlgorithm } = theme;

  const currentThemeConfig = useMemo(() => {
    return {
      algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      hashed: false,
      ...currentTheme,
    };
  }, [currentTheme]);

  return <ConfigProvider theme={currentThemeConfig}>{children}</ConfigProvider>;
};
