import {
  BanksActionsTypes,
  IFetchBanksParams,
  ISetPaginationReducerAction,
  IFetchBanksReducerAction,
} from 'modules/banks/store/banks/types';

import instance from 'utils/axios';

import { Bank } from 'modules/banks/types';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';
import { AppDispatch } from 'app/store';

export function fetchBanks(params: IFetchBanksParams & RequestParamsWithPagination) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { data, meta },
      } = await instance.get('/banks', { params });

      dispatch<IFetchBanksReducerAction>({
        type: BanksActionsTypes.FETCH_BANKS,
        payload: {
          banks: data,
          pagination: meta,
        },
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function addMainBankToRemoveDuplicate(params: Bank) {
  return {
    type: BanksActionsTypes.ADD_MAIN_BANK_TO_REMOVE_DUPLICATE,
    payload: params,
  };
}

export function removeMainBankToRemoveDuplicate() {
  return {
    type: BanksActionsTypes.ADD_MAIN_BANK_TO_REMOVE_DUPLICATE,
    payload: null,
  };
}

export function addMergeBanksToRemoveDuplicate(params: Bank) {
  return {
    type: BanksActionsTypes.ADD_MERGE_BANKS_TO_REMOVE_DUPLICATE,
    payload: params,
  };
}

export function removeMergeBanksToRemoveDuplicate(params: Bank) {
  return {
    type: BanksActionsTypes.DELETE_MERGE_BANKS_TO_REMOVE_DUPLICATE,
    payload: params,
  };
}

interface IMergeBanksParams {
  ids: number[];
}

export function mergeBanks(params: IMergeBanksParams) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setIsLoading(true));

      await instance.post('/banks/combine', { ...params });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function clearMainAndMergeBanks() {
  return {
    type: BanksActionsTypes.CLEAR_MAIN_AND_MERGE_BANK,
    payload: null,
  };
}

export function updateCheckedMainBank(params: Bank) {
  return {
    type: BanksActionsTypes.UPDATE_BANK,
    payload: params,
  };
}

export function disabledCheckedMainBank(params: Bank) {
  return {
    type: BanksActionsTypes.DISABLED_CHECKED_MAIN_BANK,
    payload: params,
  };
}

export function disabledCheckedMergeBank(params: Bank) {
  return {
    type: BanksActionsTypes.DISABLED_CHECKED_MERGE_BANK,
    payload: params,
  };
}

export function updateCheckedMergeBank(params: Bank) {
  return {
    type: BanksActionsTypes.UPDATE_BANK,
    payload: params,
  };
}

export function clearCheckedMainBank() {
  return {
    type: BanksActionsTypes.CLEAR_CHECKED_MAIN_BANK,
    payload: null,
  };
}

export function clearCheckedMergeBanks() {
  return {
    type: BanksActionsTypes.CLEAR_CHECKED_MERGE_BANK,
    payload: null,
  };
}

export function clearDisabledCheckedBanks() {
  return {
    type: BanksActionsTypes.CLEAR_DISABLED_CHECKED_BANKS,
    payload: null,
  };
}

export function setIsLoading(value: boolean) {
  return {
    type: BanksActionsTypes.SET_IS_LOADING,
    payload: value,
  };
}

export function setPagination(page: number, perPage: number) {
  return (dispatch: AppDispatch) => {
    dispatch<ISetPaginationReducerAction>({
      type: BanksActionsTypes.SET_PAGINATION,
      payload: { page, perPage },
    });
  };
}
