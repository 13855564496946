import useSWRMutation from 'swr/mutation';
import { activateBankAccountRequest } from 'modules/bankAccounts/api/activateBankAccount.request';

export function useActivateBankAccountRequest() {
  const { trigger, isMutating } = useSWRMutation('activate-bank-account-request', (_, { arg }: { arg: number }) =>
    activateBankAccountRequest(arg),
  );

  return { activateBankAccount: trigger, isActivatingBankAccount: isMutating };
}
