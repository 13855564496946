import useSWRMutation from 'swr/mutation';
import { deleteCardRequest } from 'modules/bankAccounts/api/deleteCard.request';

export function useDeleteCardRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'delete-card-request',
    (
      _,
      {
        arg,
      }: {
        arg: number;
      },
    ) => deleteCardRequest(arg),
  );

  return { deleteCard: trigger, isDeletingCard: isMutating };
}
