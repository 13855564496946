import { paginationLocale } from 'constants/locale';
import { DownloadOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { Button, Pagination, Table } from 'antd';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { Filters } from 'components/Filters';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { OwnerCreateTransactionParams } from 'modules/owners/types/OwnerCreateTransactionParams';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import {
  changePagination,
  createOwnerTransactions,
  fetchOwnersTransactions,
} from 'modules/owners/store/ownersTransactions/actions';
import { OwnersBalancePageCreateTransactionModal } from 'modules/owners/pages/transactions/components/CreateTransactionModal';
import { OwnersTransactionsPageExportDrawer } from 'modules/owners/pages/transactions/components/ExportDrawer';

import { makeColumns } from 'modules/owners/pages/transactions/components/columns';
import { filtersSchema } from 'modules/owners/pages/transactions/filters';

import { permissions } from 'policies/permissions';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { OwnersTransactionsFetchParams } from 'modules/owners/store/ownersTransactions/types';

export const OwnersTransactionPage: FC = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [addModalValue, setAddModalValue] = useState<OwnerCreateTransactionParams>({} as OwnerCreateTransactionParams);
  const dispatch = useAppDispatch();

  const [isExportDrawerShown, setIsExportDrawerShown] = useState<boolean>(false);

  const { isLoading, transactions, pagination } = useAppSelector(({ ownersTransactions }) => ownersTransactions);

  const { page, perPage, total } = pagination;

  const { memoFilters, setMemoFilters } = useMemoFilters<OwnersTransactionsFetchParams>();

  useEffect(() => {
    dispatch(fetchOwnersTransactions({ ...memoFilters, page, perPage }));
  }, [memoFilters, page, perPage]);

  const columns = useMemo(() => makeColumns(), []);

  function handleCreateModalSubmit() {
    dispatch(createOwnerTransactions(addModalValue));
    setAddModalValue({} as OwnerCreateTransactionParams);
    setIsAddModalVisible(false);
  }

  function handleExportBalancesClick() {
    setIsExportDrawerShown(true);
  }

  function handlePaginationChange(page: number, perPage: number) {
    dispatch(changePagination(page, perPage));
  }

  return (
    <>
      <OwnersTransactionsPageExportDrawer isShown={isExportDrawerShown} onClose={() => setIsExportDrawerShown(false)} />
      <OwnersBalancePageCreateTransactionModal
        isShown={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        onChange={setAddModalValue}
        onSubmit={handleCreateModalSubmit}
        params={addModalValue}
      />
      <Filters
        headerTitle="Балансы транзакций владельцев"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        extraHeaderButtons={() => (
          <ProtectedComponent requiredPermissions={[permissions.owner.balance.update]}>
            <Button
              onClick={() => setIsAddModalVisible(true)}
              type="primary"
              shape="round"
              icon={<MoneyCollectOutlined />}
            >
              Добавить
            </Button>
          </ProtectedComponent>
        )}
        customButtons={() => (
          <>
            <Button
              className="bg-green-600 border-green-600 hover:bg-green-500"
              type="default"
              icon={<DownloadOutlined />}
              onClick={() => handleExportBalancesClick()}
              style={{ color: 'white', border: 'none' }}
            >
              Скачать в .xlsx
            </Button>
          </>
        )}
      />
      <Table
        bordered
        size="small"
        rowKey="id"
        pagination={false}
        loading={isLoading}
        dataSource={transactions}
        columns={columns}
      />
      <Pagination
        current={page}
        pageSize={perPage}
        total={total}
        disabled={isLoading}
        onChange={handlePaginationChange}
        locale={paginationLocale}
        showSizeChanger
      />
    </>
  );
};
