import useSWRMutation from 'swr/mutation';
import { enableIncomingBankAccountRequest } from 'modules/bankAccounts/api/enableIncomingBankAccount.request';

export function useEnableIncomingBankAccountRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'enable-incoming-bank-account',
    (
      _,
      {
        arg,
      }: {
        arg: number;
      },
    ) => enableIncomingBankAccountRequest(arg),
  );

  return {
    enableIncomingBankAccount: trigger,
    isEnablingIncomingBankAccount: isMutating,
  };
}
