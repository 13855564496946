import React, { useEffect, useMemo, useState } from 'react';
import { Tree, message, Spin } from 'antd';
import type { TreeDataNode } from 'antd';
import { AgentReferral } from 'modules/agents/types/agentReferral.interface';
import instance from 'utils/axios';

interface Props {
  agentId: number;
}

export const AgentReferralsTree: React.FC<Props> = ({ agentId }) => {
  const [agentsReferrals, setAgentsReferrals] = useState<AgentReferral[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!agentId) {
      return;
    }

    setLoading(true);
    fetchAgentsReferrals(agentId).finally(() => setLoading(false));
  }, [agentId]);

  async function fetchAgentsReferrals(agentId: number) {
    try {
      const {
        data: {
          data: { referrals },
        },
      } = await instance.post('/agents/referrals', { agentId });

      setAgentsReferrals(referrals);
    } catch {
      message.error('Ошибка при получении рефералов агента');
    }
  }

  function transformDataForAnt(referrals: AgentReferral[]): TreeDataNode[] {
    return referrals.map((item) => ({
      key: item.id,
      title: item.name,
      children: item.referrals ? transformDataForAnt(item.referrals) : [],
    }));
  }

  const transformedReferrals = useMemo(() => {
    return transformDataForAnt(agentsReferrals);
  }, [agentsReferrals]);

  return (
    <>
      {loading && <Spin size="large" />}
      <Tree showLine defaultExpandedKeys={[]} treeData={transformedReferrals} />
    </>
  );
};
