import useSWRMutation from 'swr/mutation';
import { enableCardRequest } from 'modules/bankAccounts/api/enableCard.request';

export function useEnableCardRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'enable-card-request',
    (
      _,
      {
        arg,
      }: {
        arg: number;
      },
    ) => enableCardRequest(arg),
  );

  return { enableCard: trigger, isEnablingCard: isMutating };
}
