import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { User } from 'modules/users/types/user.interface';
import { useTheme } from 'contexts';
import React from 'react';

interface Props {
  user: User;
  onUpdateClick: (user: User) => void;
}

export const UserCard: React.FC<Props> = ({ user, onUpdateClick }) => {
  const {
    currentTheme: { token },
  } = useTheme();

  const { colorBgContainer, colorBgBlue, colorPrimary, colorTextLight } = token ?? {};

  return (
    <div
      className="w-full max-w-md flex flex-col rounded-xl shadow-lg p-4 border-t-2"
      style={{
        backgroundColor: colorBgContainer,
        color: colorTextLight,
        borderColor: colorPrimary,
      }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div
            className="rounded-lg bg-blue-500 px-2 font-semibold text-xs text-white"
            style={{ backgroundColor: colorBgBlue, color: colorPrimary }}
          >
            <span className="pr-1">
              <UserOutlined />
            </span>
            {user.id}
          </div>
          <div className="text-md font-bold">{user.name}</div>
        </div>
        <div className="flex items-center space-x-4">
          <EditOutlined onClick={() => onUpdateClick(user)} className="cursor-pointer" />
        </div>
      </div>
      <div className="mt-4 text-gray-500 font-bold text-sm">{user.email}</div>
    </div>
  );
};
