import React from 'react';
import { Image, Popover, Typography } from 'antd';
import Link from 'antd/lib/typography/Link';
import { ColumnType } from 'antd/lib/table';

import { utcDateTimeToMoscow } from 'utils';
import { Order } from 'modules/orders/types/order.interface';
import { Tooltip } from 'components/Tooltip';
import { OrdersPageTableIconCellType } from './components/IconCellType';
import { OrdersPageTableIconCellStatus } from './components/IconCellStatus';

export function makeColumns(): ColumnType<Order>[] {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Диспуты',
      dataIndex: 'disputeIds',
      key: 'disputeIds',
      render: (disputeIds: number[]) => {
        if (!disputeIds?.length) return 'Нет диспутов';

        return (
          <>
            {disputeIds.map((id: number, i) => (
              <>
                <Link href={`/order_disputes?id=${id}`}>{id}</Link>
                {i !== disputeIds.length - 1 && ', '}
              </>
            ))}
          </>
        );
      },
    },
    {
      title: 'ID Клиента',
      dataIndex: 'customerTransactionId',
      key: 'customerTransactionId',
      render: (value: string) => {
        const idWithoutAlias = value.split('_').pop();

        return <Typography.Text copyable={{ text: idWithoutAlias }}>{value}</Typography.Text>;
      },
    },
    {
      title: 'Клиент',
      dataIndex: ['customer', 'name'],
      key: 'customer.name',
    },
    {
      title: 'Создано (МСК)',
      dataIndex: 'timerOrder',
      key: 'timerOrder',
      render: (_, { timeOrder }) => {
        return timeOrder ? utcDateTimeToMoscow(timeOrder) : 'Нет данных';
      },
    },
    {
      title: 'Выполнено (МСК)',
      dataIndex: 'timeExecuteOrder',
      key: 'timeExecuteOrder',
      render: (_, { timeExecuteOrder }) => {
        return timeExecuteOrder ? utcDateTimeToMoscow(timeExecuteOrder) : 'Нет данных';
      },
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      render: (_, { type }) => {
        return <OrdersPageTableIconCellType typeValue={type} />;
      },
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
      width: '10px',
      render: (_, { amount }) => {
        return amount ? amount.toLocaleString('ru-RU') : '- - -';
      },
    },
    {
      title: 'Реальная сумма',
      dataIndex: 'realSum',
      key: 'realSum',
      render: (_, { realSum }) => {
        return realSum ? realSum.toLocaleString('ru-RU') : '- - -';
      },
    },
    {
      title: 'Провайдер',
      key: 'provider',
      dataIndex: ['provider', 'name'],
      width: 100,
      render: (_, { provider, isSbp }) => {
        if (!provider) {
          return 'Нет данных';
        }

        if (isSbp) {
          return (
            <Popover
              title="Банк перевода"
              content={<Image src={provider.image} width={60} preview={false} alt={provider.name} />}
            >
              <Image src={'https://paytransferbot.h1n.ru/img/sbp.png'} width={60} preview={false} alt={provider.name} />
            </Popover>
          );
        }

        return (
          <Tooltip title={provider.name}>
            <Image src={provider.image} width={60} preview={false} alt={provider.name} />
          </Tooltip>
        );
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '65px',
      render: (_, transaction) => {
        return <OrdersPageTableIconCellStatus order={transaction} />;
      },
    },
    {
      title: 'ФИО клиента',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: '№ карты/телефона/счета',
      dataIndex: 'customerCard',
      key: 'customerCard',
      render: (_, { customerCard, customerPhone, account }) => {
        return <>{customerCard ?? customerPhone ?? account ?? 'Нет данных'}</>;
      },
    },
  ];
}
