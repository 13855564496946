import api from 'utils/axios';
import { PaymentDirectionEnum, PaymentStatusEnum } from 'modules/payments/enums';

export interface FetchPaymentsStatisticRequestResponse {
  incomingSum: number;
  incomingCount: number;
  outcomingSum: number;
  outcomingCount: number;
}

export interface FetchPaymentsStatisticRequestParams {
  id?: number;
  bankId?: number;
  agentId?: number;
  type?: PaymentDirectionEnum;
  merchantId?: number;
  ownersId?: number;
  status?: PaymentStatusEnum;
  orderId?: number;
  from?: string;
  to?: string;
  dateType?: 'created_at' | 'paid_at';
}

export async function fetchPaymentsStatisticRequest(
  params: FetchPaymentsStatisticRequestParams,
): Promise<FetchPaymentsStatisticRequestResponse> {
  const {
    data: { data },
  } = await api.get('/payment/statistic', {
    params: { ...params },
  });

  return data;
}
