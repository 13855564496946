import { OrdersActionsTypes, OrdersReducerActions } from 'modules/orders/store/orders/types';
import { OrdersState } from './interfaces/ordersState.interface';
import { FetchOrdersPayload } from './interfaces/fetchOrdersPayload.interface';

const initialState: OrdersState = {
  orders: [],
  isLoading: false,
  pagination: {
    page: 1,
    pages: 1,
    perPage: 20,
    total: 0,
  },
  count: 0,
  sum: 0,
};

export function ordersReducer(state = initialState, { type, payload }: OrdersReducerActions): OrdersState {
  switch (type) {
    case OrdersActionsTypes.ADD_ORDER:
      return { ...state, orders: [payload, ...state.orders] };
    case OrdersActionsTypes.GET_ORDERS: {
      const { orders, pagination } = payload as FetchOrdersPayload;

      return {
        ...state,
        orders,
        pagination,
      };
    }
    case OrdersActionsTypes.REFUND_ORDER: {
      const orders = state.orders.map((orderItem) => {
        return orderItem.id === payload.id ? payload : orderItem;
      });

      return { ...state, orders };
    }

    case OrdersActionsTypes.EDIT_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) => (order.id === payload.id ? payload : order)),
      };
    case OrdersActionsTypes.SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case OrdersActionsTypes.CHANGE_PAGE: {
      const { page, perPage } = payload;

      return { ...state, pagination: { ...state.pagination, page, perPage } };
    }
    default:
      return state;
  }
}
