import React, { useEffect, useState } from 'react';
import { Drawer, Form, DatePicker, Space, Button, App } from 'antd';

import { disableDatesAfterToday } from 'modules/owners/pages/transactions/components/ExportDrawer/utils';

import { downloadAndSaveFrom } from 'utils/export';

import {
  ownerIdFieldValidatorRules,
  fromFieldValidatorRules,
  toFieldValidatorRules,
} from 'modules/owners/pages/transactions/components/ExportDrawer/formValidationRules';

import { OwnersTransactionsFetchParams } from 'modules/owners/store/ownersTransactions/types';

import { useBanksFromServer } from 'hooks/useDataFromServer';
import { Dayjs } from 'dayjs';
import { InputNumber } from 'components/Input';
import { LazySelect } from 'components/LazySelect';

interface Props {
  isShown: boolean;
  onClose: () => void;
}

export const OwnersTransactionsPageExportDrawer: React.FC<Props> = ({ isShown, onClose }) => {
  const [form] = Form.useForm<OwnersTransactionsFetchParams>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { message } = App.useApp();

  useEffect(() => {
    if (!isShown) {
      return;
    }

    form.setFieldsValue({});
  }, [isShown]);

  async function handleSubmit() {
    const dateFormat = 'YYYY-MM-DD';
    const { from, to, ...restFields } = await form.validateFields();

    try {
      setIsLoading(true);

      await downloadAndSaveFrom<OwnersTransactionsFetchParams>('/owners/balance/export', {
        ...restFields,
        from: (from as Dayjs).format(dateFormat),
        to: (to as Dayjs).format(dateFormat),
      });
    } catch {
      message.error('Ошибка при скачивании');
      onClose();
    } finally {
      setIsLoading(false);
      form.resetFields();
    }
  }

  return (
    <Drawer open={isShown} onClose={onClose} title="Экспорт балансов владельцев">
      <Form form={form} disabled={isLoading}>
        <Form.Item name="from" label="От" rules={fromFieldValidatorRules(form)}>
          <DatePicker className="w-full" disabledDate={disableDatesAfterToday} />
        </Form.Item>
        <Form.Item name="to" label="До" rules={toFieldValidatorRules(form)}>
          <DatePicker className="w-full" disabledDate={disableDatesAfterToday} />
        </Form.Item>
        <Form.Item name="ownerId" label="ID владельца" rules={ownerIdFieldValidatorRules}>
          <InputNumber className="w-full" min={0} />
        </Form.Item>
        <Form.Item name="bankId" label="Банк">
          <LazySelect useDataHook={useBanksFromServer} cacheKey="provider-filter" className="w-full" />
        </Form.Item>
      </Form>
      <Space>
        <Button type="primary" onClick={handleSubmit} loading={isLoading} disabled={isLoading}>
          Скачать
        </Button>
        <Button onClick={onClose}>Отмена</Button>
      </Space>
    </Drawer>
  );
};
