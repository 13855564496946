import React, { useMemo } from 'react';

import { Image, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { Tooltip } from 'components/Tooltip';
import { MerchantBalance } from 'modules/merchants/types';

export function useMerchantsBalancesColumns(): ColumnType<MerchantBalance>[] {
  return useMemo<ColumnType<MerchantBalance>[]>(
    () => [
      {
        title: 'Название клиента',
        key: 'customer',
        align: 'center',
        render: (_, { merchant }) => <Typography>{merchant?.name}</Typography>,
      },
      {
        title: 'Провайдер',
        key: 'provider',
        align: 'center',
        dataIndex: ['provider', 'name'],
        render: (_, { bank }) => (
          <Tooltip title={bank?.name}>
            <Image src={bank?.image} width={60} preview={false} alt={bank?.name} />
          </Tooltip>
        ),
      },
      {
        title: 'Баланс',
        key: 'amount',
        dataIndex: 'amount',
        render: (_, balance) => <span>{Number(balance.amount).toLocaleString('ru-RU')}</span>,
      },
      {
        title: 'Исходящий лимит',
        key: 'limitOut',
        dataIndex: 'limitOut',
        render: (_, balance) => <span>{Number(balance.limitOut).toLocaleString('ru-RU')}</span>,
      },
    ],
    [],
  );
}
