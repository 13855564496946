import React, { useMemo } from 'react';

import { Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import { BankTransactionDirectionEnum } from 'modules/bankTransactions/enums/bankTransactionDirection.enum';
import { BankTransaction } from 'modules/bankTransactions/types/bankTransaction.interface';

import { maskAccountNumber } from 'utils/maskAccountNumber.util';
import { utcDateTimeToLocal } from 'utils';
import { Bank } from 'modules/bankTransactions/types/bank.interface';

export function useBankTransactionsColumns(): ColumnType<BankTransaction>[] {
  return useMemo(
    () => [
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        align: 'center',
      },
      {
        title: 'ID владельца',
        key: 'ownerId',
        dataIndex: 'ownerId',
        align: 'center',
        render: (ownerId) => (
          <a className="text-primaryColor" href={`/owners?ownerId=${ownerId}`}>
            {ownerId}
          </a>
        ),
      },
      {
        title: 'ID платежа',
        key: 'paymentId',
        dataIndex: 'paymentId',
        align: 'center',
        render: (paymentId) => (
          <a className="text-primaryColor" href={`/owners/payments?id=${paymentId}`}>
            {paymentId}
          </a>
        ),
      },
      {
        title: 'ID заявки',
        key: 'orderId',
        dataIndex: 'orderId',
        align: 'center',
        render: (orderId) => (
          <a className="text-primaryColor" href={`/transactions_static?id=${orderId}`}>
            {orderId}
          </a>
        ),
      },
      {
        title: 'Номер счёта',
        key: 'accountNumber',
        dataIndex: 'accountNumber',
        align: 'center',
        render: (accountNumber) => (
          <a className="text-primaryColor" href={`/owners/bank-accounts?accountNumber=${accountNumber}`}>
            {maskAccountNumber(accountNumber)}
          </a>
        ),
      },
      {
        title: 'Банк',
        key: 'bankId',
        align: 'center',
        dataIndex: 'bank',
        width: '150px',
        render: (bank: Bank) => <Typography.Text className="whitespace-nowrap">{bank?.name}</Typography.Text>,
      },
      {
        title: 'Сумма транзакции',
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
        width: '10px',
        render: (amount) => amount.toLocaleString('ru-RU') || '- - -',
      },
      {
        title: 'Тип транзакции',
        key: 'direction',
        dataIndex: 'direction',
        align: 'center',
        render: (direction) => {
          switch (direction) {
            case BankTransactionDirectionEnum.IN:
              return <ArrowDownOutlined className="text-xl text-customGreen" />;
            case BankTransactionDirectionEnum.OUT:
              return <ArrowUpOutlined className="text-xl text-primaryColor" />;
            default:
              return null;
          }
        },
      },
      {
        title: 'Дата транзакции',
        key: 'paidAt',
        dataIndex: 'paidAt',
        align: 'center',
        render: (paidAt) => utcDateTimeToLocal(paidAt) || '',
      },
      {
        title: 'Баланс после транзакции',
        key: 'balanceAfter',
        dataIndex: 'balanceAfter',
        align: 'center',
        render: (balance) => balance.toLocaleString('ru-RU') || '',
      },
      {
        title: 'Комиссия транзакции',
        key: 'commission',
        dataIndex: 'commission',
        align: 'center',
        render: (commission) => commission?.toLocaleString('ru-RU') || '',
      },
    ],
    [],
  );
}
