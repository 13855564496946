import instance from 'utils/axios';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { IMetaPagination } from 'types/IMetaPagination';

export interface FetchBankAccountsRequestParams extends RequestParamsWithPagination {
  accountId?: string;
  cardId?: string;
  status?: string;
  ownerId?: number;
  agentId?: number;
  bankId?: number;
}

export interface FetchBankAccountsRequestResponse {
  data: BankAccount[];
  meta: IMetaPagination;
}

export async function fetchBankAccountsRequest(
  params: FetchBankAccountsRequestParams,
): Promise<FetchBankAccountsRequestResponse> {
  const { data } = await instance.get('bank-accounts', { params: sanitizeRequestParams(params) });

  return data;
}
