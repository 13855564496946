import React, { useEffect, useState } from 'react';
import { App, Switch } from 'antd';

import { Bank } from 'modules/banks/types';
import { useToggleSbpBankRequest } from 'modules/banks/hooks';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';

interface Props {
  bank: Bank;
  onChange?: (checked: boolean) => void;
}

export const BankSbpEnabledSwitch: React.FC<Props> = ({ bank, onChange }) => {
  const [isChecked, setIsChecked] = useState<boolean>(bank.sbpEnabled);

  const { toggleSbpOnBank, isTogglingSbpOnBank } = useToggleSbpBankRequest();

  const { message } = App.useApp();

  const { userCanAll } = useUserPermissions();

  useEffect(() => {
    setIsChecked(bank.sbpEnabled);
  }, [bank]);

  async function handleSbpEnabledBankChange(checked: boolean) {
    try {
      await toggleSbpOnBank({ id: bank.id, enabled: checked });

      setIsChecked(checked);
      onChange?.(checked);

      message.success(`СБП для банка успешно ${checked ? 'включено' : 'выключено'}`);
    } catch {
      message.error(`Не удалось ${checked ? 'включить' : 'выключить'} СБП для банка`);
    }
  }

  if (!userCanAll(permissions.bank.sbp.enable, permissions.bank.sbp.disable)) {
    return null;
  }

  return <Switch checked={isChecked} loading={isTogglingSbpOnBank} onChange={handleSbpEnabledBankChange} />;
};
