import useSWRMutation from 'swr/mutation';
import {
  updateBankAccountRequest,
  UpdateBankAccountRequestParams,
} from 'modules/bankAccounts/api/updateBankAccount.request';

export function useUpdateBankAccountRequest() {
  const { trigger, isMutating } = useSWRMutation(
    'update-bank-account-request',
    (
      _,
      {
        arg,
      }: {
        arg: UpdateBankAccountRequestParams;
      },
    ) => updateBankAccountRequest(arg),
    {
      throwOnError: true,
    },
  );

  return { updateBankAccount: trigger, isUpdatingBankAccount: isMutating };
}
