import React from 'react';
import { Space, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

interface Props {
  onCloseClick: () => void;
  onReloadClick: () => void;
}

export const UpdateMessageBanner: React.FC<Props> = ({ onReloadClick, onCloseClick }) => {
  return (
    <Space className="w-full p-3 border-red-300 border justify-between">
      <div>
        <b className="text-base">Доступно обновление для админ-панели. </b>
        Рекомендуем обновить страницу во избежание проблем и багов.
      </div>
      <Space>
        <Button type="primary" onClick={onReloadClick}>
          Обновить
        </Button>
        <Button icon={<CloseOutlined />} onClick={onCloseClick} />
      </Space>
    </Space>
  );
};
