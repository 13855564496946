import React from 'react';

import Link from 'antd/lib/typography/Link';
import { ColumnType } from 'antd/lib/table';
import { Image } from 'antd';

import { IOwnerSms } from 'modules/owners/store/ownersSms/types';

import { Tooltip } from 'components/Tooltip';
import { OrderTypes } from 'modules/orders/enums/orderTypes.enum';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { utcDateTimeToMoscow } from 'utils';

dayjs.extend(utc);
dayjs.extend(tz);

export const tableColumns: ColumnType<IOwnerSms>[] = [
  {
    key: 'ownerId',
    title: 'ID владельца',
    dataIndex: 'ownerId',
  },
  {
    key: 'orderId',
    title: 'ID заявки',
    render: (_, { orderId }) => {
      if (!orderId) {
        return 'Нет привязанной заявки';
      } else {
        return (
          <Tooltip title="Перейти к заявке" className="whitespace-nowrap">
            <Link href={'/transactions_static?id=' + orderId} style={{ color: '#008ecc' }}>
              {orderId}
            </Link>
          </Tooltip>
        );
      }
    },
  },
  {
    key: 'provider',
    title: 'Провайдер',
    render: (_, { provider }) => (
      <Tooltip title={provider?.name}>
        <Image src={provider?.imageUrl} width={60} preview={false} alt={provider?.name} />
      </Tooltip>
    ),
  },
  {
    key: 'sum',
    title: 'Сумма',
    render: (_, { sum }) => sum?.toLocaleString('ru-RU') ?? '',
  },
  {
    key: 'commission',
    title: 'Комиссия',
    render: (_, { commission }) => commission?.toLocaleString('ru-RU') ?? '',
  },
  {
    key: 'type',
    title: 'Тип',
    render: (_, { paymentType }) => {
      if (paymentType === null) return 'Нет данных';

      return paymentType === OrderTypes.INCOMING ? 'Входящий' : 'Исходящий';
    },
  },
  {
    key: 'currentBalance',
    title: 'Баланс',
    render: (_, { currentBalance }) => currentBalance?.toLocaleString('ru-RU') ?? '',
  },
  {
    key: 'message',
    title: 'Текст смс',
    dataIndex: 'message',
  },
  {
    key: 'time',
    title: 'Время в СМС',
    dataIndex: 'time',
  },
  {
    key: 'createdAt',
    title: 'Дата получения (МСК)',
    dataIndex: 'createdAt',
    render: (createdAt: string) => utcDateTimeToMoscow(createdAt),
  },
];
