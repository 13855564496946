import React, { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip, Image, Drawer, Spin } from 'antd';

import { AccountTypeEnum } from 'modules/owners/types/accountType.enum';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';
import { useTheme } from 'contexts';
import {
  BankAccountCardsList,
  BankAccountOutcomingEnabledSwitch,
  DeleteBankAccountButton,
  BankAccountStatusSelect,
  BankAccountSbpEnabledSwitch,
  BankAccountIncomingEnabledSwitch,
  UpdateBankAccountForm,
} from 'modules/bankAccounts/components';
import { useFetchBankAccountRequest } from 'modules/bankAccounts/hooks/useFetchBankAccountRequest.hook';

interface Props {
  id: number;
  onDeleteSuccess?: () => void;
}

export const BankAccountCard: React.FC<Props> = ({ id, onDeleteSuccess }) => {
  const [isUpdateBankAccountFormShown, setIsUpdateBankAccountFormShown] = useState<boolean>(false);

  const { bankAccount, isFetchingBankAccount, fetchBankAccount } = useFetchBankAccountRequest(id);

  const { userCan } = useUserPermissions();

  const {
    currentTheme: { token },
  } = useTheme();

  const { colorBgBalance, colorBgLabelInput, colorBorderInput } = token ?? {};

  if (!bankAccount) {
    return (
      <div className="flex flex-col w-full border rounded-lg mb-2" style={{ borderColor: colorBorderInput }}>
        <Spin />
      </div>
    );
  }

  return (
    <Spin spinning={isFetchingBankAccount}>
      {isUpdateBankAccountFormShown && (
        <Drawer
          open={isUpdateBankAccountFormShown}
          title="Редактирование счета"
          onClose={() => setIsUpdateBankAccountFormShown(false)}
        >
          {isUpdateBankAccountFormShown && (
            <UpdateBankAccountForm
              id={bankAccount.id}
              onSuccess={() => {
                setIsUpdateBankAccountFormShown(false);
                fetchBankAccount();
              }}
            />
          )}
        </Drawer>
      )}
      <div className="flex flex-col w-full border rounded-lg mb-2" style={{ borderColor: colorBorderInput }}>
        <div style={{ backgroundColor: colorBgLabelInput }} className="rounded-lg">
          <div className="flex flex-row items-center justify-between pt-4 pb-2 px-4">
            <div className="flex flex-col flex-1">
              <div className="flex flex-row items-center justify-between">
                <Tooltip title={bankAccount.bank.name}>
                  <Image
                    className="py-4"
                    src={bankAccount.bank.image}
                    width={90}
                    preview={false}
                    alt={bankAccount.bank.name}
                  />
                </Tooltip>
                <div className="flex flex-col flex-1 pl-3">
                  <div className="flex flex-row gap-x-2 ml-1 justify-between">
                    <div className="flex flex-row gap-x-2 ml-1 ">
                      <div
                        className={`text-sm whitespace-nowrap font-semibold ${
                          !bankAccount.accountNumber && 'text-red-500'
                        }`}
                      >
                        Счет {bankAccount.accountNumber ? bankAccount.accountNumber : 'отсутствует'}
                      </div>
                      <div>
                        <p
                          className="rounded-full px-2"
                          style={
                            bankAccount.type.id === AccountTypeEnum.OPERATING
                              ? { color: '#335366', backgroundColor: '#C2E9FF' }
                              : { color: '#33663C', backgroundColor: '#C0F2B1' }
                          }
                        >
                          {bankAccount.type.name}
                        </p>
                      </div>
                    </div>
                    <div className="flex end">
                      <Tooltip title="Редактировать счёт">
                        <Button
                          onClick={() => setIsUpdateBankAccountFormShown(true)}
                          shape="circle"
                          type="primary"
                          icon={<EditOutlined />}
                          disabled={!userCan(permissions.bankAccount.update)}
                          className="mr-4"
                        />
                      </Tooltip>
                      <DeleteBankAccountButton id={bankAccount.id} onSuccess={() => onDeleteSuccess?.()} />
                    </div>
                  </div>
                  <div className="flex flex-row w-full justify-between mt-2 mb-2">
                    <div className="ml-1">
                      <div className={`text-sm font-semibold ${!bankAccount.bic && 'text-gray-300'}`}>
                        БИК {bankAccount.bic ? bankAccount.bic : 'отсутствует'}
                      </div>
                      <div className={`text-sm font-semibold ${!bankAccount.bic && 'text-gray-300'}`}>
                        Номер телефона {bankAccount.phone ? bankAccount.phone : 'отсутствует'}
                      </div>
                      <div className={`text-sm font-semibold ${!bankAccount.limitMinIn && 'text-gray-300'}`}>
                        Мин. сумма вх. платежа {bankAccount.limitMinIn}
                      </div>
                      <div className="flex flex-row">
                        <p
                          style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            backgroundColor: colorBgBalance,
                          }}
                          className="rounded-full mb-1 mt-1 border-black"
                        >
                          Баланс: {bankAccount.amount?.toLocaleString('ru-RU') ?? ''} ₽
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-row gap-x-4 mb-2 items-center">
                  <BankAccountStatusSelect account={bankAccount} />
                  <div className="flex gap-x-2">
                    <BankAccountIncomingEnabledSwitch account={bankAccount} />
                    Входящие
                  </div>
                  <div className="flex gap-x-2">
                    <BankAccountOutcomingEnabledSwitch account={bankAccount} />
                    Исходящие
                  </div>
                  <div className="flex gap-x-2">
                    <BankAccountSbpEnabledSwitch account={bankAccount} />
                    СБП
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BankAccountCardsList
            bankAccountId={bankAccount.id}
            addRowProps={{ className: 'px-4', addButtonPosition: 'right' }}
          />
        </div>
      </div>
    </Spin>
  );
};
